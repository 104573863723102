import { useEffect } from "react";
import usePlacesAutocompleteService from "react-google-autocomplete/lib/usePlacesAutocompleteService";

export const useGoogleAddress = () => {
  const {
    placesService,
    placePredictions,
    getPlacePredictions,
    isPlacePredictionsLoading,
  } = usePlacesAutocompleteService({
    apiKey: process.env.REACT_APP_GOOGLEMAP_API,
  });

  useEffect(() => {
    // fetch place details for the first element in placePredictions array
    if (placePredictions.length)
      placesService?.getDetails(
        {
          placeId: placePredictions[0].place_id,
        },
        (placeDetails) => {
          return placeDetails;
        }
      );
  }, [placePredictions, placesService]);

  return {
    getPlacePredictions,
    isPlacePredictionsLoading,
    placesService,
    placePredictions,
  };
};
