export const filterByPrice = (allStations, selectedProduct) => {
  return allStations
    ?.filter(
      (el) =>
        el.products?.filter(
          (product) =>
            product?.name?.toLowerCase() ===
            selectedProduct?.value?.toLowerCase()
        ).length > 0
    )
    ?.sort((a, b) => {
      const firstObj = a.products?.find(
        (product) =>
          product?.name?.toLowerCase() === selectedProduct?.value?.toLowerCase()
      )?.price;
      const secondObj = b.products?.find(
        (product) =>
          product?.name?.toLowerCase() === selectedProduct?.value?.toLowerCase()
      )?.price;

      if (
        (firstObj === -1 || firstObj === 0) &&
        secondObj !== -1 &&
        secondObj !== 0
      ) {
        return 1;
      } else if (
        firstObj !== -1 &&
        firstObj !== 0 &&
        (secondObj === -1 || secondObj === 0)
      ) {
        return -1;
      } else if (
        firstObj !== -1 &&
        firstObj !== 0 &&
        secondObj !== 0 &&
        secondObj !== -1
      ) {
        return firstObj - secondObj;
      }

      return 0;
    });
};

export const filterByDistance = (allStations, selectedProduct) => {
  return allStations
    ?.filter(
      (el) =>
        el.products?.filter(
          (product) =>
            product?.name?.toLowerCase() ===
            selectedProduct?.value?.toLowerCase()
        ).length > 0
    )
    ?.sort((a, b) => {
      const firstObj = a.products?.find(
        (product) =>
          product?.name?.toLowerCase() === selectedProduct?.value?.toLowerCase()
      )?.price;
      const secondObj = b.products?.find(
        (product) =>
          product?.name?.toLowerCase() === selectedProduct?.value?.toLowerCase()
      )?.price;

      if (
        (firstObj === -1 || firstObj === 0) &&
        secondObj !== -1 &&
        secondObj !== 0
      ) {
        return 1;
      } else if (
        firstObj !== -1 &&
        firstObj !== 0 &&
        (secondObj === -1 || secondObj === 0)
      ) {
        return -1;
      } else if (
        firstObj !== -1 &&
        firstObj !== 0 &&
        secondObj !== 0 &&
        secondObj !== -1
      ) {
        return a?.distanceToTarger - b?.distanceToTarger;
      }

      return 0;
    });
};

export const filterByAddPrice = (allStations, selectedProduct) => {
  return allStations
    ?.filter(
      (el) =>
        el.products?.filter(
          (product) =>
            product?.name?.toLowerCase() ===
            selectedProduct?.value?.toLowerCase()
        ).length > 0
    )
    ?.sort((a, b) => {
      const firstObj = a.products?.find(
        (product) =>
          product?.name?.toLowerCase() === selectedProduct?.value?.toLowerCase()
      )?.price;
      const secondObj = b.products?.find(
        (product) =>
          product?.name?.toLowerCase() === selectedProduct?.value?.toLowerCase()
      )?.price;

      if (
        (firstObj === -1 || firstObj === 0) &&
        secondObj !== -1 &&
        secondObj !== 0
      ) {
        return -1;
      } else if (
        firstObj !== -1 &&
        firstObj !== 0 &&
        (secondObj === -1 || secondObj === 0)
      ) {
        return 1;
      }

      return 0;
    });
};
