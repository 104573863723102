import React from "react";
import emailSent from "../../../assets/svg/emailsent.svg";
import { NavLink } from "react-router-dom";

export default function PasswordSent() {
  return (
    <section>
      <section className="w-[90%]  mx-auto mt-40">
        <div className="w-full flex justify-center">
          <img src={emailSent} alt="Check your mail" />
        </div>
        <div className="mt-8 text-center">
          <h1 className="text-2xl font-semibold">Check your mail</h1>
          <p className="mt-4 w-[80%] mx-auto">
            We have sent a confirmation link to your mail.
          </p>

          <p className="text-sm w-[50%] text-[#686565] mx-auto mt-10">
            If you did not get a mail, click{" "}
            <NavLink to={"/forgot-password"}>
              <span className="text-orange-600">here</span>
            </NavLink>{" "}
            to try again
          </p>
        </div>

        <div className="w-full flex items-center justify-center">
          <NavLink to="/login">
            <button
              className={`  bg-[var(--orange600)]
             my-10  mt-5 mx-auto text-xs py-3 px-7 text-white w-[100%] rounded `}
            >
             Proceed to login
            </button>
          </NavLink>
        </div>
      </section>
    </section>
  );
}
