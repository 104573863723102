import React, { useState } from "react";
import { useSwipeable } from "react-swipeable";

export default function OnboardingCarousel() {
  const [currentSlide, setCurrentSlide] = useState(0);

  const handlers = useSwipeable({
    onSwipedLeft: () => {
      if (currentSlide < 2) {
        setCurrentSlide(currentSlide + 1);
      }
    },
    onSwipedRight: () => {
      if (currentSlide > 0) {
        setCurrentSlide(currentSlide - 1);
      }
    },
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  return (
    <div
      {...handlers}
      style={{
        display: "flex",
        overflowX: "hidden",
        transition: "transform 0.3s ease",
        transform: `translateX(-${currentSlide * 100}%)`,
      }}
    >
      <div
        className="slide"
        style={{
          flex: "0 0 100%",
          width: "100%",
          background: "red",
        }}
      >
        Slide 1
      </div>
      <div
        className="slide"
        style={{
          flex: "0 0 100%",
          width: "100%",
          background: "green",
        }}
      >
        Slide 2
      </div>
      <div
        className="slide"
        style={{
          flex: "0 0 100%",
          width: "100%",
          background: "blue",
        }}
      >
        Slide 3
      </div>
    </div>
  );
}
