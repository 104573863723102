import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { _getUserProfile } from "../apiList/apiList";

const initialState = {
  userProfile: {
    isLoading: true,
    data: null,
    error: null,
  },
};

export const getUserProfile = createAsyncThunk(
  "fetchUserProfile/getUserProfile",
  async (params) => {
    const response = await _getUserProfile(params);
    return response?.data;
  }
);

export const fetchUserProfileSlice = createSlice({
  name: "fetchUserProfile",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getUserProfile.pending, (state) => {
        state.userProfile.isLoading = true;
      })
      .addCase(getUserProfile.fulfilled, (state, { payload }) => {
        state.userProfile.isLoading = false;
        state.userProfile.data = payload;
      })
      .addCase(getUserProfile.rejected, (state, { payload }) => {
        state.userProfile.isLoading = false;
        state.userProfile.error = true;
      });
  },
});

export default fetchUserProfileSlice.reducer;
