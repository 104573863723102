import facebook from "../../assets/svg/facebook.svg";
import whatsapp from "../../assets/svg/WS.svg";
import twitter from "../../assets/svg/twitter.svg";
import thread from "../../assets/svg/thread.svg";
import React from "react";
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import { sharePriceWidget } from "../../utils/sharePriceWidget";
import { useLocation } from "react-router-dom";

export default function ShareButton({ url }) {
  const { pathname } = useLocation();

  return (
    <section className="py-8">
      <p className="text-center py-4 w-[80%] mx-auto font-semibold">
        Did you find this helpful? Share with others!
      </p>
      <section className="flex justify-center">
        <img src={facebook} alt="Share to facebook" className="w-[4.2rem]" />
        <img src={twitter} alt="Share to twitter" className="w-[4.2rem]" />
        <img src={whatsapp} alt="Share to whatsapp" className="w-[4.2rem]" />
        <img src={thread} alt="Share to thread" className="w-[4.2rem]" />
        {/* <FacebookShareButton url={url}>
        <img src={facebook} alt="Share to facebook" />
      </FacebookShareButton>

      <TwitterShareButton url={url}>
        <img src={twitter} alt="Share to facebook" />
      </TwitterShareButton>

      <WhatsappShareButton url={url}>
        <img src={whatsapp} alt="Share to facebook" />
      </WhatsappShareButton> */}
      </section>
      <div
        onClick={(e) => sharePriceWidget(e, pathname)}
        className="text-white flex items-center justify-center py-2 rounded-lg mt-2 bg-black w-[50%] mx-auto text-center"
      >
        <ShareSVG />
        <p className="ml-2"> Share </p>
      </div>
    </section>
  );
}

export const ShareSVG = () => {
  return (
    <svg
      width="20"
      height="16"
      viewBox="0 0 14 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.3382 10.7623C10.5716 10.7623 9.88096 11.0934 9.40159 11.62L5.08996 8.94957C5.20507 8.65489 5.26891 8.33482 5.26891 7.99998C5.26891 7.66502 5.20507 7.34496 5.08996 7.0504L9.40159 4.37987C9.88096 4.90648 10.5716 5.23766 11.3382 5.23766C12.7823 5.23766 13.9571 4.06286 13.9571 2.61877C13.9571 1.17468 12.7823 0 11.3382 0C9.89415 0 8.71934 1.1748 8.71934 2.61889C8.71934 2.95373 8.78331 3.2738 8.8983 3.56847L4.58678 6.23888C4.10741 5.71227 3.41674 5.38109 2.65014 5.38109C1.20605 5.38109 0.03125 6.55602 0.03125 7.99998C0.03125 9.44407 1.20605 10.6189 2.65014 10.6189C3.41674 10.6189 4.10741 10.2878 4.58678 9.76109L8.8983 12.4315C8.78331 12.7262 8.71934 13.0462 8.71934 13.3812C8.71934 14.8252 9.89415 16 11.3382 16C12.7823 16 13.9571 14.8252 13.9571 13.3812C13.9571 11.9371 12.7823 10.7623 11.3382 10.7623ZM9.6743 2.61889C9.6743 1.70141 10.4208 0.954954 11.3382 0.954954C12.2557 0.954954 13.0022 1.70141 13.0022 2.61889C13.0022 3.53637 12.2557 4.28283 11.3382 4.28283C10.4208 4.28283 9.6743 3.53637 9.6743 2.61889ZM2.65014 9.66392C1.73254 9.66392 0.986082 8.91746 0.986082 7.99998C0.986082 7.0825 1.73254 6.33605 2.65014 6.33605C3.56762 6.33605 4.31396 7.0825 4.31396 7.99998C4.31396 8.91746 3.56762 9.66392 2.65014 9.66392ZM9.6743 13.3811C9.6743 12.4636 10.4208 11.7171 11.3382 11.7171C12.2557 11.7171 13.0022 12.4636 13.0022 13.3811C13.0022 14.2986 12.2557 15.045 11.3382 15.045C10.4208 15.045 9.6743 14.2986 9.6743 13.3811Z"
        fill="white"
      />
    </svg>
  );
};
