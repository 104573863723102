import React, { useEffect, useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";

import picon from "../../assets/svg/picon.svg";
import romade from "../../assets/svg/romade.svg";
import arrowback from "../../assets/svg/arrowback.svg";
import { EarnPoints, MyPoints } from "./sections";
import { GetRewardsApi } from "../../services/api/rewards.api";
import { useSwipeable } from "react-swipeable";

const data = [
  {
    id: 1,
    name: "Olaitan",
    points: 5000,
    position: "1st",
    imageSrc: picon,
    bg: "var(--inputGray)",
  },
  {
    id: 2,
    name: "Teymee",
    points: 3000,
    position: "2nd",
    imageSrc: romade,
    bg: "var(--inputGray)",
  },
  {
    id: 3,
    name: "Deji",
    points: 2800,
    position: "3rd",
    imageSrc: picon,
    bg: "var(--inputGray)",
  },
  {
    id: 4,
    name: "Deji",
    points: 2800,
    position: "3rd",
    imageSrc: romade,
    bg: "var(--inputGray)",
  },

  {
    id: 5,
    name: "Deji",
    points: 2800,
    position: "3rd",
    imageSrc: picon,
    bg: "var(--inputGray)",
  },
];

const tabs = [
  { id: 0, title: "Earn Points", slug: "earn" },
  { id: 1, title: "History", slug: "my" },
];

export default function Rewards() {
  const location = useLocation();
  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState("earn");
  const [rewardsData, setRewardsData] = useState({});

  const updateTab = (choice) => {
    setActiveTab(choice);
  };

  const goBack = () => {
    navigate(-1); // Navigates back to the previous location
  };

  const rewards = async () => {
    try {
      const res = await GetRewardsApi();

      setRewardsData(res);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    rewards();
  }, []);

    const handlers = useSwipeable({
    onSwipedLeft: (eventData) => setActiveTab("my"),
    onSwipedRight: (eventData) => setActiveTab("earn"),
  });

  return (
    <section className="bg-[#FDFAFA] p-2 py-6" {...handlers}>
      <section className="container ">
        <section>
          <div className="flex items-center py-4 gap-x-1 ">
            <img src={arrowback} alt="" onClick={goBack} />
            <div className=" ml-4 text-[#252525] font-bold text-xl font-custom">
              Reward
            </div>
          </div>

          <div className="flex items-center justify-center font-custom">
            {tabs.map(({ id, title, slug }) => (
              <div
                className={`${
                  activeTab === slug && "border-red-600"
                } w-full transition ease-in-out duration-200 delay-50 cursor-pointer px-[2rem]  py-2  border-b-4 text-xs`}
                onClick={() => updateTab(slug)}
                key={id}
              >
                <p className="pb-2 text-sm text-center">{title}</p>
              </div>
            ))}
          </div>
        </section>

        <section className="mx-auto w-[90%]">
          {activeTab === "earn" && (
            <EarnPoints rewards={rewardsData} data={data} />
          )}
          {activeTab === "my" && <MyPoints rewards={rewardsData} />}
        </section>
      </section>
    </section>
  );
}
