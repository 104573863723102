import React from "react";
import { breakNumber } from "../../utils/breakNumber";
import { getProductPrice } from "../../utils/getProductPrice";

export default function StationPrice({ details, price }) {
  let pmsPrice,
    getPmsPrice,
    getDpkPrice,
    dpkPrice,
    getAgoPrice,
    agoPrice,
    getLpgPrice,
    lpgPrice;

  if (details) {
    //GET PMS PRICE
    getPmsPrice = getProductPrice(details, "pms");
    pmsPrice = breakNumber(getPmsPrice);

    //GET DPK PRICE
    getDpkPrice = getProductPrice(details, "dpk");
    dpkPrice = breakNumber(getDpkPrice);

    //GET AGO PRICE
    getAgoPrice = getProductPrice(details, "ago");
    agoPrice = breakNumber(getAgoPrice);

    //GET LPG PRICE
    getLpgPrice = getProductPrice(details, "lpg");
    lpgPrice = breakNumber(getLpgPrice);
  }

  return (
    <section className={`mt-4 text-base font-semibold`}>
      <h1>Station prices</h1>
      <div className="mt-4">
        <div
          className={`border-[1px] rounded-lg flex justify-between items-center px-4 py-2 mt-2 border-[var(--grey300)]`}
        >
          <p className={` text-base font-semibold`}>Petrol</p>
          {pmsPrice && (
            <h2 className={` text-base font-bold`}>
              NGN {pmsPrice[0]}.
              <sup className="text-xs font-bold">{pmsPrice[1]}</sup>{" "}
            </h2>
          )}

          {!pmsPrice && <h2 className={` text-base font-bold`}>---</h2>}
        </div>

        <div
          className={`border-[1px] rounded-lg flex justify-between items-center px-4 py-2 mt-2 border-[var(--grey300)]`}
        >
          <p className={` text-base font-semibold`}>Diesel</p>
          {agoPrice && (
            <h2 className={` text-base font-bold`}>
              NGN {agoPrice[0]}.
              <sup className="text-xs font-bold">{agoPrice[1]}</sup>{" "}
            </h2>
          )}

          {!agoPrice && <h2 className={` text-base font-bold`}>---</h2>}
        </div>

        <div
          className={`border-[1px] rounded-lg flex justify-between items-center px-4 py-2 mt-2 border-[var(--grey300)]`}
        >
          <p className={` text-base font-semibold`}>Kerosene</p>
          {dpkPrice && (
            <h2 className={` text-base font-bold`}>
              NGN {dpkPrice[0]}.
              <sup className="text-xs font-bold">{dpkPrice[1]}</sup>{" "}
            </h2>
          )}

          {!dpkPrice && <h2 className={` text-base font-bold`}>---</h2>}
        </div>

        <div
          className={`border-[1px] rounded-lg flex justify-between items-center px-4 py-2 mt-2 border-[var(--grey300)]`}
        >
          <p className={` text-base font-semibold`}>
            Gas <span className="text-xs">(Kg)</span>
          </p>
          {lpgPrice && (
            <h2 className={` text-base font-bold`}>
              NGN {lpgPrice[0]}.
              <sup className="text-xs font-bold">{lpgPrice[1]}</sup>{" "}
            </h2>
          )}

          {!lpgPrice && <h2 className={` text-base font-bold`}>---</h2>}
        </div>
      </div>

      {/* <div
        className={`border-[1px] px-4 py-7 mt-4 border-[var(--grey300)] flex justify-between rounded-lg`}
      >
        <div>
          <h4 className={`text-xs text-center text-[var(--black800)] `}>PMS</h4>
          {pmsPrice && (
            <h2 className={`mt-4 text-xl text-center font-bold`}>
              <sup className="text-xs font-bold">NGN</sup>
              {pmsPrice[0]}.
              <sub className="text-xs font-bold">{pmsPrice[1]}</sub>{" "}
            </h2>
          )}
        </div>

        <div>
          <h4 className={`text-xs text-center text-[var(--black800)] `}>AGO</h4>
          <h2 className={`mt-4 text-xl text-center font-bold`}>
            <sup className="text-xs font-bold">NGN</sup>0.
            <sub className="text-xs font-bold">00</sub>{" "}
          </h2>
        </div>

        <div>
          <h4 className={`text-xs text-center text-[var(--black800)] `}>DPK</h4>
          <h2 className={`mt-4 text-xl text-center font-bold`}>
            <sup className="text-xs font-bold">NGN</sup>0.
            <sub className="text-xs font-bold">00</sub>{" "}
          </h2>
        </div>
      </div> */}
    </section>
  );
}
