import React, { useEffect, useState } from "react";

import paperPlus from "../../assets/svg/paperPlus.svg";

import { NavLink } from "react-router-dom";
import axios from "../../lib/axiosInterceptor";
import Loader from "../../components/Loader";
import MySuggestions from "../../components/StationList/MySuggestions";

// import { useDispatch, useSelector } from "react-redux";
import ErrorWrapper from "../../components/ErrorHandler/ErrorWrapper";
import EarnPoints from "../Rewards/sections/EarnPoints";
import { GetRewardsApi } from "../../services/api/rewards.api";

import gift from "../../assets/svg/gift.svg";
import { useSwipeable } from "react-swipeable";
import { getDeviceHeight } from "../../utils/getDeviceHeight";
import { BiGasPump } from "react-icons/bi";

export default function StationList() {
  // const dispatch = useDispatch();

  //USESTATES
  const [showStationList, setshowStationList] = useState(true);
  const [numberOfSuggestions, setNumberOfSuggestions] = useState();
  const [mySuggestions, setMySuggestions] = useState();
  const [rewardsData, setRewardsData] = useState({});

  const getMySuggestions = () => {
    axios.get("/Station/SuggestedByMe").then((res) => {
      setMySuggestions(res.data?.dataObject);
      setNumberOfSuggestions(res.data.dataObject?.length);
    });
  };

  useEffect(() => {
    getMySuggestions();
  }, []);

  const rewards = async () => {
    try {
      const res = await GetRewardsApi();

      setRewardsData(res);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    rewards();
  }, []);

  const handlers = useSwipeable({
    onSwipedLeft: (eventData) => setshowStationList(false),
    onSwipedRight: (eventData) => setshowStationList(true),
  });

  let deviceHeight = getDeviceHeight;
  return (
    <ErrorWrapper>
      <>
        {!mySuggestions && <Loader />}

        {mySuggestions && (
          <section
            {...handlers}
            style={{ height: deviceHeight }}
            className={`w-[90%] mx-auto relative`}
          >
            <section className="fixed bg-white top-0 w-[90%] h-[5rem]  z-10">
              <section>
                <section
                  className={`bg-[var(--grey10)] h-[4rem]   flex justify-between`}
                >
                  <div
                    className={`w-[50%]  h-[100%] cursor-pointer ${
                      showStationList
                        ? " border-b-4 border-[var(--orange600)]  "
                        : " border-b-4 border-[#E2E2E2)]"
                    }  text-center font-semibold`}
                    onClick={() => setshowStationList(true)}
                  >
                    <h1
                      className={` text-sm pt-[1.25rem] ${
                        showStationList
                          ? " text-black"
                          : "text-[var(--grey500)]"
                      }`}
                    >
                      Rewards
                    </h1>
                  </div>

                  <div
                    className={`w-[50%] cursor-pointer   h-[100%]  ${
                      !showStationList
                        ? " border-b-4 border-[var(--orange600)]  "
                        : " border-b-4 border-[#E2E2E2)]"
                    } text-center font-semibold`}
                    onClick={() => setshowStationList(false)}
                  >
                    <h1
                      className={`text-sm pt-[1.25rem] ${
                        !showStationList
                          ? " text-black"
                          : "text-[var(--grey500)]"
                      }`}
                    >
                      My Suggestions ({numberOfSuggestions})
                    </h1>
                  </div>
                </section>
              </section>
            </section>

            <section className={`mt-[5rem] z-3`}>
              {!showStationList && (
                <MySuggestions mySuggestions={mySuggestions} />
              )}

              {showStationList && (
                <EarnPoints image={gift} rewards={rewardsData} />
              )}
            </section>

            <div className="fixed bottom-24 right-5 ">
              {!showStationList && (
                <NavLink to="/suggest-station">
                  <button className="flex text-xs shadow-xl bg-[var(--orange600)] text-white p-3 rounded-full mt-2  items-center">
                    {" "}
                    <img
                      src={paperPlus}
                      alt="suggest a station"
                      className="mr-2"
                    />{" "}
                    Suggest a station
                  </button>
                </NavLink>
              )}

              {showStationList && (
                <NavLink to="/dashboard?addprice">
                  <button className="flex text-xs shadow-xl bg-[var(--orange600)] text-white p-3 rounded-full mt-2  items-center">
                    {" "}
                    <BiGasPump size={20} className="mr-1" />
                    Report Price
                  </button>
                </NavLink>
              )}
            </div>
          </section>
        )}
      </>
    </ErrorWrapper>
  );
}
