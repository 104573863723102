import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { _fetchStationDetails } from "../apiList/apiList";

const initialState = {
  isLoading: false,
  data: null,
  error: null,
};

export const fetchStationDetails = createAsyncThunk(
  "getStationDetails/fetchStationDetails",
  async (id) => {
    const response = await _fetchStationDetails(id);

    return response?.data?.dataObject;
  }
);

export const getStationDetailsSlice = createSlice({
  name: "getStationDetails",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(fetchStationDetails.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchStationDetails.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.data = payload;
      })
      .addCase(fetchStationDetails.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.error = true;
      });
  },
});

export default getStationDetailsSlice.reducer;
