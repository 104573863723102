export const triggerLocationPrompt = (
  setIsLocationPromptWorking,
  success,
  error
) => {
  if (navigator.geolocation) {
    try {
      navigator.geolocation.getCurrentPosition(success, error);
    } catch (err) {
      console.log("An error occurred while getting the current position:", err);
      // Handle the error here
      // For example, you can display an error message to the user or perform any other necessary actions
      error();
    }
  } else {
    console.log("Geolocation is not supported by this browser.");
    try {
      let androidPosition = window.Android.onGetLocation();
      if (androidPosition && androidPosition != null) {
        success(window.Android.onGetLocation());
      } else {
        error();
      }
    } catch (err) {
      console.log(err);
    }
  }
};
