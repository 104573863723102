import React from 'react'

export default function ListFilter({setFilterType, onDismiss}) {
  return (
    <div className="w-[90%] text-sm pt-3 mx-auto pb-8">
    <button
      onClick={() => {
        setFilterType("distance");
        onDismiss();
      }}
      className="block w-full mt-2 mb-4 outline-none text-left"
    >
      Sort by Distance
    </button>
    <button
      onClick={() => {
        setFilterType("price");
        onDismiss();
      }}
      className="block w-full mt-2 mb-4 text-left"
    >
      Sort by Price
    </button>

    <button
      onClick={() => {
        setFilterType("add price");
        onDismiss();
      }}
      className="block w-full mt-2  mb-4 text-left"
    >
      Sort by Add Price
    </button>
  </div>
  )
}
