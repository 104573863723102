import React from "react";
import smallgift from "../../../assets/svg/smallgift.svg";
import { NavLink } from "react-router-dom";

const EarnPoints = ({ data, rewards, image }) => {
  return (
    <section className="">
      <div className="flex flex-col items-center justify-center py-2">
        <div className="my-4">
          <div className="flex justify-center mb-4">
            <img src={image ? image : smallgift} className="w-[80px]" alt="" />
          </div>
          <div>
            <p className="text-[#252525] text-center mb-2 w-[99%] mx-auto font-semibold text-sm font-custom pt-2">
              Earn over 100 points when you suggest an active station in your
              area or report the current price of stations nearby.
            </p>
          </div>
        </div>

        <div className="px-3 py-4 rounded-lg border-[0.8px] shadow-sm w-[100%]  ">
          <div className="flex items-center justify-between mb-2">
            <p className="text-sm font-bold font-custom">My points</p>
            <NavLink to={"/dashboard"}>
              <button className="bg-[#101010] text-xs text-white font-custom px-3 py-1 text-center rounded">
                Earn points
              </button>
            </NavLink>
          </div>
          <p className="text-[#252525] font-custom text-lg font-bold">
            {rewards.totalPoints}
          </p>
        </div>
      </div>

      <section>
        <div className="leaderboard--heading text-[#101010] text-base mt-5">
          <div className="mb-1 font-black">Leaders board</div>
          <div className="text-xs font-normal ">
            Only users with 1,000 points will appear here
          </div>
        </div>

        <section className="my-4">
          <p className="my-10 text-sm font-semibold text-center">Leaderboard currently empty</p>
          {/* <div className="w-full rounded-sm font-custom">

            {!data && <div className="font-bold text-center mt-7"><h1>No active user with points</h1> </div>
              }

            { data && data?.map(({ id, bg, imageSrc, name, points, position }) => (
              <div
                key={id}
                className={`flex border-[0.2px] justify-between items-center p-4 shadow-sm rounded-lg  mb-2 `}
              >
                <div className="flex items-center justify-between">
                  <div className="mr-2 ">
                    <img
                      src={imageSrc}
                      alt=""
                      className="object-contain rounded-full w-9 h-9"
                    />
                  </div>
                  <div className="text-[#101828] font-semibold text-sm">
                    {name}
                  </div>
                </div>
                <div>
                  <span className="flex items-center gap-x-1 ">
                    <p className="font-bold text-base text-[#101828]">
                      {points}
                    </p>
                    <span className="text-xs font-normal">points</span>
                  </span>

                  <p className="text-[#888484] text-xs font-light">
                    {position}
                  </p>
                </div>
              </div>
            ))}
          </div> */}
        </section>
      </section>
    </section>
  );
};

export default EarnPoints;
