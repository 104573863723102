import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";

//libraries
import axios from "axios";
import { toast } from "react-toastify";
import { ClipLoader } from "react-spinners";

//assets
import logo from "../../assets/svg/logo.svg";
import Show from "../../assets/svg/showEye.svg";

// import AdSense from "react-adsense";
// import AdsComponent from "../../utils/AdsComponent";


export default function Login() {
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [inputType, setInputType] = useState("password");

  const navigate = useNavigate();

  useEffect(() => {
    showPassword ? setInputType("text") : setInputType("password");
  }, [showPassword]);

  const loginUser = (e) => {
    e.preventDefault();
    setIsLoading(true);

    let email = e.target.email?.value;
    let password = e.target.password?.value;

    if (!email) {
      toast.error("Enter your email address");
      setIsLoading(false);
      return;
    }

    if (!password) {
      toast.error("Enter password");

      setIsLoading(false);
      return;
    }

    let data = {
      email,
      password,
    };

    axios
      .post(`${process.env.REACT_APP_BASE_URL}/Loginbypassword`, data) 
      .then((res) => {
        localStorage.setItem(
          "users-token",
          JSON.stringify(res?.data?.dataObject)
        );

        navigate({
          pathname: "/dashboard",
        });
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);

        toast.error(err?.response?.data?.message);
      });
  };

  useEffect(() => {
    // toast.info("hey");
  }, []);

  return (
    <section className={`mt-[3.125rem] w-[87%] mx-auto`}>
      <section>
        <div className={`flex w-full justify-center`}>
          <img src={logo} alt="logo" className={`w-[2.8125rem] h-[2.375rem]`} />
        </div>

        <div className={`mt-10`}>
          <h1 className={`text-xl font-bold`}>Login</h1>
          <p className={`text-[var(--black400)] text-sm mt-2`}></p>
        </div>
      </section>

      <div className={`mt-8`}>
        <form onSubmit={loginUser}>
          <div className="mt-4">
            <label className={`block text-sm font-semibold pl-1`}>
              Email address
            </label>
            <input
              required
              type="email"
              name="email"
              placeholder="Enter email address"
              className={`mt-2 py-4 pl-4 w-full rounded-lg bg-[var(--inputGray)] placeholder:text-[var(--black10)] text-sm`}
            />
          </div>

          <div className="mt-4">
            <label className={`block text-sm font-semibold pl-1`}>
              Password
            </label>
            <div
              className={` flex items-center justify-between mt-2   w-full rounded-lg bg-[var(--inputGray)] placeholder:text-[var(--black10)] text-sm`}
            >
              <input
                required
                name="password"
                type={inputType}
                placeholder="Enter password"
                className={`  py-4 pl-4 rounded-lg w-[90%] outline-none bg-[var(--inputGray)] placeholder:text-[var(--black10)] text-sm`}
              />
              <img
                src={Show}
                className="mr-5"
                onClick={() => {
                  setShowPassword((showPassword) => !showPassword);
                }}
                alt="show password"
              />
            </div>
          </div>
          <div className="flex items-center justify-between mt-2">
            <p className="text-xs ">
              Don't have an account?{" "}
              <NavLink to="/register">
                {" "}
                <span className="font-bold text-[var(--orange600)]">
                  Sign up
                </span>
              </NavLink>
            </p>

            <NavLink to="/forgot-password">
              <p className="text-xs font-bold text-[var(--orange600)]">
                Forgot password?
              </p>
            </NavLink>
          </div>

          {/* <AdsComponent dataAdSlot="7534830653"/> */}
          {/* <AdSense.Google
            client={process.env.REACT_APP_ADSENSE_CLIENT}
            slot="7534830653"
            style={{ display: "block" }}
            layout="in-article"
            format="fluid"
          /> */}

          {/* absolute bottom-2 left-[50%] translate-x-[-50%] */}
          <button
            className={`   ${
              isLoading
                ? "disabled  bg-[var(--orange60070)]"
                : " bg-[var(--orange600)]"
            } my-10  mx-auto w-full text-sm py-4 text-white rounded-lg `}
            disabled={isLoading ? true : false}
          >
            {" "}
            {isLoading ? <ClipLoader color="white" size={12} /> : " Login"}
          </button>
        </form>
      </div>
    </section>
  );
}
