import React, { useEffect, useState } from "react";

import { NavLink, useLocation } from "react-router-dom";
import { Modal } from "antd";
import { InsertAD } from "../../utils/insertAD";
import { getStationsByProducts } from "../../utils/getStationsByProduct";
import { triggerLocationPrompt } from "../../utils/triggerLocationPrompt";
import { getPriceRange } from "../../utils/getPriceRange";
import { useDispatch, useSelector } from "react-redux";
import { getCloseStations } from "../../store/Slices/fetchCloseStationsSlice";
import { getStationsByState } from "../../store/Slices/fetchStationsByState";
import { setNetworkError } from "../../store/Slices/ErrorHandlingSlice";
import { handleOfflineError } from "../../utils/handleOfflineError";

import Select from "react-select";
import "react-spring-bottom-sheet/dist/style.css";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";


//assets
import noSug from "../../assets/svg/noSug.svg";
import locationIcon from "../../assets/svg/locationIcon.svg";
import image from "../../assets/svg/udraw.svg";

//components
import PriceRange from "../../components/Dashboard/PriceRange";
import ErrorWrapper from "../../components/ErrorHandler/ErrorWrapper";
import Loader from "../../components/Loader";

export default function DemoPage() {
  const dispatch = useDispatch();

  //USESELECTORS
  const { closeStations } = useSelector((state) => state.closeStations);
  const { stationsByState } = useSelector((state) => state.stationsByState);

  //USESTATES
  const [stations, setStations] = useState([]);
  const [priceRange, setPriceRange] = useState();
  const [numberOfStation, setNumberOfStation] = useState();
  const [isLocationPromptWorking, setIsLocationPromptWorking] = useState(false);
  const [state, setState] = useState("lagos");
  const [place, setPlace] = useState();
  const { pathname } = useLocation();
  const [searchedLocation, setSearchedLocation] = useState();
  const [searchedAddress, setSearchedAddress] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState({
    value: "PMS",
    label: "PMS",
  });

  //CUSTOM FUNCTIONS

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const getFewStations = (filteredStation) => {
    //GET HIGHEST AND LOWEST PRICE PER PRODUCT & NUMBER OF STATIONS
    if (filteredStation) {
      let filteredPrice = getPriceRange(filteredStation, selectedProduct);
      setPriceRange({
        highest: Math.max(...filteredPrice),
        lowest: Math.min(...filteredPrice),
      });

      setNumberOfStation(filteredStation?.length);
      ///

      let stationsWithPrice = filteredStation?.filter((el) => {
        let pmsPrice = el?.products?.find(
          (product) =>
            product?.name?.toLowerCase() ===
            selectedProduct?.value?.toLowerCase()
        )?.price;

        return pmsPrice && pmsPrice !== -1 && pmsPrice !== 0;
      });

      let limitedStations = stationsWithPrice?.slice(
        stationsWithPrice?.length - 4,
        stationsWithPrice?.length
      );

      return limitedStations;
    }
  };

  const {
    placesService,
    placePredictions,
    getPlacePredictions,
    isPlacePredictionsLoading,
  } = usePlacesService({
    apiKey: process.env.REACT_APP_GOOGLEMAP_API,
  });

  ///

  //USEEFFECTS

  useEffect(() => {
    window.addEventListener("offline", handleOfflineError);

    return () => {
      window.removeEventListener("offline", handleOfflineError);
    };
  }, []);

  //SHOW LOGIN POPUP
  useEffect(() => {
    if (stations?.length > 0) {
      let modalTimer = setTimeout(() => {
        showModal();
      }, 4500);

      return () => {
        clearTimeout(modalTimer);
      };
    }
  }, [stations]);

  //FETCH USER'S LOCATION AND CLOSE STATIONS ON RENDER
  useEffect(() => {
    function success(position) {
      const latitude = position.coords.latitude;
      const longitude = position.coords.longitude;

      if (latitude && longitude) {
        dispatch(getCloseStations({ lat: latitude, long: longitude, page: 1 }));
      } else {
        dispatch(getStationsByState(state));
      }
    }

    function error(err) {
      dispatch(getStationsByState(state));

      if (err.code === err.TIMEOUT || err.code === err.POSITION_ERROR) {
        console.log(err);
        dispatch(setNetworkError(true));
      }

      // console.log(err);

      console.log("Unable to retrieve your location");
    }

    triggerLocationPrompt(setIsLocationPromptWorking, success, error);
  }, []);

  //FORMAT STATIONLIST BASED ON SELECTED PRODUCT
  useEffect(() => {
    if (closeStations) {
      if (closeStations?.length > 0) {
        setStations(
          getFewStations(
            getStationsByProducts(closeStations, selectedProduct, "distance")
          )
        );
        setPlace("You");
      } else {
        //FETCH LAGOS STATE STATIONS
        dispatch(getStationsByState(state));
      }
    }
  }, [closeStations]);

  //FORMAT LAGOS STATE STATIONS
  useEffect(() => {
    if ((closeStations?.length < 1 || !closeStations) && stationsByState) {
      setStations(
        getFewStations(
          getStationsByProducts(stationsByState, selectedProduct, "distance")
        )
      );
      setPlace(state);
    }
  }, [stationsByState]);

  //GOOGLE AUTOCOMPLETE
  useEffect(() => {
    try {
      // fetch place details for the first element in placePredictions array
      if (placePredictions?.length) {
        placesService?.getDetails(
          {
            placeId: placePredictions[0].place_id,
          },
          (placeDetails) => {
            return placeDetails;
          }
        );
      }
    } catch (error) {
      dispatch(setNetworkError(true));
    }
  }, [placePredictions]);

  //GET LOCATIONS FROM SEARCH BAR
  useEffect(() => {
    if (searchedLocation) {
      dispatch(getCloseStations(searchedLocation));
      setStations(null);
      setPlace(searchedAddress);
    }
  }, [searchedLocation]);

  return (
    <ErrorWrapper>
      <>
        {(stations?.length < 1 || !stations) && <Loader />}

        {stations?.length > 0 && (
          <section className={`w-[90%] mx-auto `}>
            {/* MENU */}
            <section className="fixed top-0 w-full z-10 bg-white">
              <section className="w-[90%] pb-4">
                <section
                  className={`pl-2 flex items-center mt-3 bg-[var(--inputGray)] py-3 w-full rounded-lg`}
                >
                  <img src={locationIcon} alt="Location icon" />

                  <Select
                    placeholder="Search your location"
                    onChange={(selectedOption) => {
                      const placeId = selectedOption.value;
                      setSearchedAddress(selectedOption.label);

                      placesService?.getDetails(
                        {
                          placeId: placeId,
                        },
                        (placeDetails) => {
                          const latitude = placeDetails.geometry.location.lat();
                          const longitude =
                            placeDetails.geometry.location.lng();

                          setSearchedLocation({
                            lat: latitude,
                            long: longitude,
                          });
                        }
                      );
                    }}
                    onInputChange={(inputValue) => {
                      getPlacePredictions({ input: inputValue });
                    }}
                    isLoading={isPlacePredictionsLoading}
                    options={placePredictions.map((item) => ({
                      value: item.place_id,
                      label: item.description,
                    }))}
                    className={`ml-1 w-[90%] bg-[var(--inputGray)] outline outline-0`}
                    styles={{
                      control: (provided, state) => ({
                        ...provided,
                        border: "none",
                        outline: "none",
                        backgroundColor: "transparent", // Set the background color to transparent
                      }),
                    }}
                  />
                </section>

                <PriceRange
                  priceRange={priceRange}
                  numberOfStation={numberOfStation?.toLocaleString()}
                />

                <section className={`mt-4`}>
                  <div className="flex justify-between mt-4 items-center">
                    <p className="text-xs ml-2 font-semibold ">
                      {" "}
                      {`Petrol price around  ${
                        place ? place : "..."
                      }`}
                    </p>

                    <NavLink to={"/login"}>
                      <p className="text-xs ml-2  bg-[var(--orange600)] text-white px-6 rounded py-2 font-semibold">
                        Sign-in
                      </p>
                    </NavLink>
                  </div>
                </section>
              </section>
            </section>

            {/* STATION LIST */}
            <section className="mt-[90%] md:mt-[39%] lg:mt-[23%]  ">
              <div className="mt-4 mb-8">
                {stations && (
                  <div className="flex flex-col">
                    {stations &&
                      InsertAD(
                        stations,
                        selectedProduct,
                        "",
                        pathname,
                        showModal
                      ).map((station, index) => (
                        <div key={index}>{station}</div>
                      ))}
                  </div>
                )}

                {stations && stations?.length < 1 && (
                  <div className="flex flex-col justify-center w-full">
                    <div className="text-center text-sm font-semibold mt-20 flex  flex-col   justify-center">
                      <img
                        src={noSug}
                        alt="You have no suggestions"
                        className="mb-6 w-[5.5rem] h-[5.375rem] mx-auto "
                      />
                      <p> We can't find a station nearby</p>
                    </div>
                  </div>
                )}

                {/* {!isLocationPromptWorking && !stations && (
                <p className="text-center text-sm font-semibold mt-20 w-[90%] mx-auto">
                  Search for your area or enable your location to show you
                  stations nearby.
                </p>
              )} */}
              </div>
            </section>
          </section>
        )}

        <Modal
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
          centered
          width={320}
          footer={false}
        >
          <p className="mt-10 mb-10 text-[var(--orange600)] text-xs font-semibold text-center leading-relaxed ">
            Sign in to see the prices of other products in over{" "}
            <span className="font-bold">12,000</span> stations near you and
            enjoy more features.
          </p>

          <div className="w-full flex justify-center mb-4">
            <img src={image} className="h-40 w-40" alt="" />
          </div>

          <div className="w-full flex justify-center pb-10">
            <NavLink to={"/login"}>
              <button className="text-xs ml-2 bg-[var(--orange600)] text-white px-10 rounded py-3 font-semibold">
                Sign in
              </button>
            </NavLink>
          </div>
        </Modal>
      </>
    </ErrorWrapper>
  );
}
