import React, { useEffect, useState } from "react";

import axios from "axios";
import { toast } from "react-toastify";
import { NavLink, useNavigate } from "react-router-dom";
import { ClipLoader } from "react-spinners";

//assets
import logo from "../../assets/svg/logo.svg";
import Show from "../../assets/svg/showEye.svg";
// import { baseUrl } from "../../utils/baseUrl";

export default function RegisterForm() {
  const navigate = useNavigate();

  //usestate
  const [showPassword, setShowPassword] = useState(false);
  const [inputType, setInputType] = useState("password");
  const [isLoading, setIsLoading] = useState(false);
  const [isValid, setIsValid] = useState(true);
  const [hasUpperCase, setHasUpperCase] = useState(false);
  const [hasLowerCase, setHasLowerCase] = useState(false);
  const [hasNumber, setHasNumber] = useState(false);
  const [hasSpecial, setHasSpecial] = useState(false);
  const [isLongEnough, setIsLongEnough] = useState(false);

  //useeffect
  useEffect(() => {
    showPassword ? setInputType("text") : setInputType("password");
  }, [showPassword]);

  //custom functions
  const checkPassword = (e) => {
    let entry = e.target.value;

    //check for uppercase

    setHasUpperCase(/[A-Z]/.test(entry));
    setHasLowerCase(/[a-z]/.test(entry));
    setHasNumber(/[0-9]/.test(entry));
    setHasSpecial(/[\W_]/.test(entry));
    setIsLongEnough(entry.length > 7);
  };

  //http request
  const registerUser = (e) => {
    e.preventDefault();
    setIsLoading(true);

    let firstName = e.target.firstname?.value;
    let lastName = e.target.lastname?.value;
    let email = e.target.email?.value;
    let phoneNumber = e.target.phone?.value;
    let password = e.target.password?.value;
    if (!firstName) {
      toast.error("Enter your first name");
      setIsLoading(false);
      return;
    }

    if (!lastName) {
      toast.error("Enter your last name");
      setIsLoading(false);
      return;
    }

    if (!email) {
      toast.error("Enter your email address");
      setIsLoading(false);
      return;
    }

    if (!phoneNumber) {
      toast.error("Enter your phone number");

      setIsLoading(false);
      return;
    }

    if (!password) {
      toast.error("Enter password");

      setIsLoading(false);
      return;
    }

    if (!isLongEnough) {
      toast.error("Password must be 8 digit long");
      setIsLoading(false);
      return;
    }

    if (!hasLowerCase) {
      toast.error("Password must contain a lowercase letter");
      setIsLoading(false);
      return;
    }

    if (!hasUpperCase) {
      toast.error("Password must contain an Uppercase letter");
      setIsLoading(false);
      return;
    }

    if (!hasNumber) {
      toast.error("Password must contain a number");
      setIsLoading(false);
      return;
    }

    if (!hasSpecial) {
      toast.error("Password must contain a special character");
      setIsLoading(false);
      return;
    }

    // const regex =
    //   /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;

    // if (!regex.test(password)) {
    //   toast.error(
    //     "Password should contain a Lowercase letter, Uppercase letter, At least a numuber, And a special character"
    //   );
    //   setIsValid(false);
    //   setIsLoading(false);
    //   return;
    // }

    let data = {
      firstName,
      lastName,
      email,
      phoneNumber,
      password,
    };

    axios
      .post(`${process.env.REACT_APP_BASE_URL}/signup`, data)
      .then((res) => {
        localStorage.setItem("email", JSON.stringify(email));
        toast.success("Enter OTP");
        navigate({
          pathname: "/OTP",
        });
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err?.message);
        let serverMsg = err?.response?.data?.message;
        if (serverMsg) {
          toast.error(serverMsg);
        } else {
          toast.error(err?.message);
        }
      });
  };

  return (
    <section className={`mt-10 w-[90%] mx-auto`}>
      <section>
        <div className={`flex w-full justify-center`}>
          <img src={logo} alt="logo" className={`w-[2.8125rem] h-[2.375rem]`} />
        </div>

        <div className={`mt-8`}>
          <h1 className={`text-xl font-bold`}>Sign up!</h1>
          <p className={`text-[var(--black400)] text-sm mt-2`}>
            Discover the best fuel prices around you! Enter your details below
            to get started.
          </p>
        </div>
      </section>

      <div className={`mt-8`}>
        <form onSubmit={registerUser}>
          <div>
            <label className={`block text-sm font-semibold pl-1`}>
              Firstname
            </label>
            <input
              required
              name="firstname"
              placeholder="Enter your first name"
              className={`mt-2 py-4 pl-4 w-full rounded-lg bg-[var(--inputGray)] placeholder:text-[var(--black10)] text-sm`}
            />
          </div>

          <div className="mt-4">
            <label className={`block text-sm font-semibold pl-1`}>
              Lastname
            </label>
            <input
              required
              name="lastname"
              placeholder="Enter your last name"
              className={`mt-2 py-4 pl-4 w-full rounded-lg bg-[var(--inputGray)] placeholder:text-[var(--black10)] text-sm`}
            />
          </div>

          <div className="mt-4">
            <label className={`block text-sm font-semibold pl-1`}>
              Email address
            </label>
            <input
              required
              type="email"
              name="email"
              placeholder="Enter email address"
              className={`mt-2 py-4 pl-4 w-full rounded-lg bg-[var(--inputGray)] placeholder:text-[var(--black10)] text-sm`}
            />
          </div>

          <div className="mt-4">
            <label className={`block text-sm font-semibold pl-1`}>
              Phone number
            </label>
            <input
              required
              type="tel"
              name="phone"
              placeholder="Enter phone number"
              className={`mt-2 py-4 pl-4 w-full rounded-lg bg-[var(--inputGray)] placeholder:text-[var(--black10)] text-sm`}
            />
          </div>

          <div className="mt-4">
            <label className={`block text-sm font-semibold pl-1`}>
              Password
            </label>
            <div
              className={` flex items-center justify-between mt-2   w-full rounded-lg bg-[var(--inputGray)] placeholder:text-[var(--black10)] text-sm`}
            >
              <input
                required
                name="password"
                type={inputType}
                onChange={(e) => checkPassword(e)}
                placeholder="Enter password"
                className={` ${
                  !isValid && "border-red-600"
                } py-4 pl-4 rounded-lg w-[90%] outline-none bg-[var(--inputGray)] placeholder:text-[var(--black10)] text-sm`}
              />
              <img
                src={Show}
                className="mr-5"
                onClick={() => {
                  setShowPassword((showPassword) => !showPassword);
                }}
                alt="show password"
              />
            </div>
            <div
              className={`text-xs leading-normal mt-2 ${
                !isValid  ? "text-green-600" : "text-red-600"
              }`}
            >
              <p
                className={`${
                  isLongEnough ? "text-green-600" : "text-red-600"
                }`}
              >
                Password must be at least 8 digit long
              </p>
              <p
                className={`${
                  hasLowerCase ? "text-green-600" : "text-red-600"
                }`}
              >
                Password should contain a Lowercase letter
              </p>
              <p
                className={`${
                  hasUpperCase ? "text-green-600" : "text-red-600"
                }`}
              >
                Uppercase letter
              </p>
              <p className={`${hasNumber ? "text-green-600" : "text-red-600"}`}>
                At least a numuber
              </p>
              <p
                className={`${hasSpecial ? "text-green-600" : "text-red-600"}`}
              >
                And a special character
              </p>
            </div>
          </div>

          <button
            className={` ${
              isLoading
                ? "disabled  bg-[var(--orange60070)]"
                : " bg-[var(--orange600)]"
            } my-10  mt-5 mx-auto text-sm py-4 text-white w-full rounded-lg `}
            disabled={isLoading ? true : false}
          >
            {" "}
            {isLoading ? <ClipLoader color="white" size={12} /> : " Sign up"}
          </button>
        </form>

        <div className="flex mt-[-1.25rem] mb-10 items-center">
          <p className="mr-2"> Already have an account? </p>
          <NavLink to="/login">
            {" "}
            <p className="  font-bold text-[var(--orange600)]">Sign in</p>
          </NavLink>
        </div>
      </div>
    </section>
  );
}
