import React, { useEffect, useState } from "react";
import SplashScreen from "../../components/Register/SplashScreen";
import RegisterForm from "../../components/Register/RegisterForm";

export default function Register() {
  const [promptScreen, setPromptScreen] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setPromptScreen(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <section>
      {/* <div
        className={`fixed inset-0  z-50 bg-white transition-transform duration-1000 ease-in-out ${
          promptScreen ? "translate-x-0" : "translate-x-full"
        }`}
      >
        {promptScreen && <SplashScreen />}
      </div> */}

      <RegisterForm />
    </section>
  );
}
