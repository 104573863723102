import { configureStore } from "@reduxjs/toolkit";
import thunk from "redux-thunk";

import fetchCloseStationsSlice from "./Slices/fetchCloseStationsSlice";
import fetchStationsByState from "./Slices/fetchStationsByState";
import ErrorHandlingSlice from "./Slices/ErrorHandlingSlice";
import fetchUserProfileSlice from "./Slices/fetchUserProfile";
import getStationDetailsSlice from "./Slices/getStationDetails";
import deleteUserAcctSlice from "./Slices/deleteUserAcctSlice";

export const store = configureStore({
  reducer: {
    closeStations: fetchCloseStationsSlice,
    stationsByState: fetchStationsByState,
    userProfile: fetchUserProfileSlice,
    errorHandling: ErrorHandlingSlice,
    reduxStationDetails: getStationDetailsSlice,
    deleteUser: deleteUserAcctSlice
  },
  middleware: [thunk],
});
