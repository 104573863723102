import React from "react";
import forgotPassword from "../../../assets/svg/forgotPassword.svg";
import { useState } from "react";
import { ClipLoader } from "react-spinners";
import { toast } from "react-toastify";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { getDeviceHeight } from "../../../utils/getDeviceHeight";
// import { baseUrl } from "../../../utils/baseUrl";

export default function ForgotPassword() {
  const [isLoading, setIsLoading] = useState(false);
  let deviceHeight = getDeviceHeight

  const navigate = useNavigate();
  const sendPasswordLink = (e) => {
    e.preventDefault();
    setIsLoading(true);

    let email = e.target.email?.value;

    console.log(email);
    if (!email) {
      toast.error("Enter your email address");
      setIsLoading(false);
      return;
    }

    let data = {
      email,
    };
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/ForgetPassword/${email}`, data)
      .then((res) => {
        localStorage.setItem("email", email);
        navigate({
          pathname: "/password-sent",
        });
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err?.message);
        let serverMsg = err?.response?.data?.message;
        if (serverMsg) {
          toast.error(serverMsg);
        } else {
          toast.error(err?.message);
        }
      });
  };

  return (
    <section style={{height:deviceHeight}} className="flex flex-col items-center justify-center">
     
        <div className="">
          <img
            src={forgotPassword}
            alt="Forgot password"
            className="w-[13.2881rem] h-[10.8125rem]"
          />
        </div>

        <form onSubmit={sendPasswordLink} className="mt-10 w-[90%]">
          <h3 className="text-2xl font-bold ">Forgot password?</h3>
          <p className="mt-2">
            Don’t worry! it happens. please enter the address associated with
            your account.
          </p>

          <div className="mt-8">
            <label className={`block text-base font-semibold pl-1`}>
              Email address
            </label>
            <input
              required
              name="email"
              placeholder="Enter your email address "
              className={`mt-2 py-4 pl-4 w-full rounded-lg bg-[var(--inputGray)] placeholder:text-[var(--black10)] text-sm`}
            />
          </div>
          <button
            className={` ${
              isLoading
                ? "disabled  bg-[var(--orange60070)]"
                : " bg-[var(--orange600)]"
            } my-10  mt-5 mx-auto text-sm py-4 text-white w-full rounded-lg `}
            disabled={isLoading ? true : false}
          >
            {" "}
            {isLoading ? <ClipLoader color="white" size={12} /> : " Submit"}
          </button>
        </form>
    </section>
  );
}
