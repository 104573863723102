import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { _deleteUserAcct } from "../apiList/apiList";

const initialState = {
  isLoading: false,
  data: null,
};

export const DeleteUserAcct = createAsyncThunk(
  "deleteAcct/DeleteUserAcct",
  async () => {
    const response = await _deleteUserAcct();
    console.log(response);
    return response.data;
  }
);

export const deleteUserAcctSlice = createSlice({
  name: "deletAcct",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(DeleteUserAcct.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(DeleteUserAcct.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.data = payload;
      });
  },
});

export default deleteUserAcctSlice.reducer;
