import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { _getStationsByState } from "../apiList/apiList";

const initialState = {
  isStationsByStateLoading: false,
  stationsByState: null,
  error: null,
};

export const getStationsByState = createAsyncThunk(
  "fetchStationsByState/getStationsByState",
  async (params) => {
    const response = await _getStationsByState(params);
    return response?.data?.dataObject;
  }
);

export const fetchStationsByStateSlice = createSlice({
  name: "fetchStationsByState",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getStationsByState.pending, (state) => {
        state.isStationsByStateLoading = true;
      })
      .addCase(getStationsByState.fulfilled, (state, { payload }) => {
        state.isStationsByStateLoading = false;
        state.stationsByState = payload?.slice(0, 100);
        localStorage.setItem("totalRecord", payload?.pagination?.totalRecord);
      })
      .addCase(getStationsByState.rejected, (state, { payload }) => {
        state.isStationsByStateLoading = false;
        state.error = true;
      });
  },
});

export default fetchStationsByStateSlice.reducer;
