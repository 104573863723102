import React from "react";

import noSug from "../../assets/svg/noSug.svg";

import { NavLink } from "react-router-dom";

export default function NoNearbyStation() {
  return (
    <div className="flex flex-col justify-center w-full">
      <div className="text-center text-sm font-semibold mt-4 flex  flex-col   justify-center">
        <img
          src={noSug}
          alt="You have no suggestions"
          className="mb-2  w-[5.5rem] h-[5.375rem] mx-auto pl-5 "
        />
        <p className="text-xs mb-2 w-[80%] mx-auto">
          {" "}
          We can't find a station nearby, click below to suggest stations within
          this area
        </p>

        <NavLink to="/suggest-station">
          <button
            className={`  bg-[var(--orange600)]
my-10  mt-2 mx-auto text-sm py-3 text-xs text-white w-[40%] rounded `}
          >
            Suggest stations
          </button>
        </NavLink>
      </div>
    </div>
  );
}
