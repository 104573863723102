import React, { useRef, useState } from "react";
import logo from "../../assets/svg/logo.svg";

import { ClipLoader } from "react-spinners";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import axios from "axios";
// import AdsComponent from "../../utils/AdsComponent";
// import AdSense from "react-adsense";
// import { baseUrl } from "../../utils/baseUrl";

export default function OTP() {
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const inputRefs = useRef([]);

  const handleInputChange = (event, index) => {
    const numericValue = event.target.value.replace(/[^0-9]/g, "");
    event.target.value = numericValue;

    if (numericValue.length === 1 && index < inputRefs.current.length - 1) {
      inputRefs.current[index + 1].focus();
    }
  };

  const submitOTP = (e) => {
    e.preventDefault();
    setIsLoading(true);

    let first = e.target.first?.value;
    let second = e.target.second?.value;
    let third = e.target.third?.value;
    let fouth = e.target.fouth?.value;

    if (!first || !second || !third || !fouth) {
      setIsLoading(false);
      toast.error("Fill up the fields");
      return;
    }

    let otp = `${first}${second}${third}${fouth}`;

    let email = "";
    if (localStorage.getItem("email")) {
      email = JSON.parse(localStorage.getItem("email"));
    }

    let data = {
      email,
      code: otp,
    };
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/confirmEmail`, data)
      .then((res) => {
        localStorage.setItem(
          "users-token",
          JSON.stringify(res?.data?.dataObject)
        );

        navigate({
          pathname: "/dashboard",
        });
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
        toast.error(err?.response?.data?.message);
      });
  };

  return (
    <section className="mt-10 mx-auto w-[85%] ">
      <div>
        <div className={`flex w-full justify-center`}>
          <img src={logo} alt="logo" className={`w-[2.8125rem] h-[2.375rem]`} />
        </div>

        <div className={`mt-8`}>
          <h1 className={`text-[1.75rem] font-bold`}>OTP</h1>
          <p className={`text-[var(--black400)] mt-2`}>
            One more step! Please enter the OTP sent to your phone or email
            address and press “Confirm”
          </p>
        </div>
      </div>

      <form className="mt-4 " onSubmit={submitOTP}>
        <div className="flex">
          <input
            type="number"
            ref={(el) => (inputRefs.current[0] = el)}
            onChange={(e) => handleInputChange(e, 0)}
            name="first"
            className="rounded-l-lg w-[3.5rem] text-center h-[3.5rem] bg-[var(--inputGray)] mr-4"
          />
          <input
            type="number"
            ref={(el) => (inputRefs.current[1] = el)}
            onChange={(e) => handleInputChange(e, 1)}
            name="second"
            className="w-[3.5rem] text-center h-[3.5rem] bg-[var(--inputGray)] mr-4"
          />
          <input
            type="number"
            ref={(el) => (inputRefs.current[2] = el)}
            onChange={(e) => handleInputChange(e, 2)}
            name="third"
            className="w-[3.5rem] text-center h-[3.5rem] bg-[var(--inputGray)] mr-4"
          />
          <input
            type="number"
            ref={(el) => (inputRefs.current[3] = el)}
            onChange={(e) => handleInputChange(e, 3)}
            name="fouth"
            className="rounded-r-lg w-[3.5rem] h-[3.5rem] text-center bg-[var(--inputGray)] mr-4"
          />
        </div>

        {/* <AdsComponent dataAdSlot="7534830653"/> */}

        {/* <AdSense.Google
          client={process.env.REACT_APP_ADSENSE_CLIENT}
          slot="8156775046"
          style={{ display: "block" }}
          layout="in-article"
          format="fluid"
        /> */}

        {/* absolute bottom-2 left-[50%] translate-x-[-50%] */}
        <button
          className={` ${
            isLoading
              ? "disabled  bg-[var(--orange60070)]"
              : " bg-[var(--orange600)]"
          } my-10 mx-auto text-sm py-4 text-white w-full rounded-lg `}
          disabled={isLoading ? true : false}
        >
          {" "}
          {isLoading ? <ClipLoader color="white" size={12} /> : "Confirm"}
        </button>
      </form>
    </section>
  );
}
