import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

//slices
import { getCloseStations } from "../../store/Slices/fetchCloseStationsSlice";
import { getStationsByState } from "../../store/Slices/fetchStationsByState";

//components
import PriceRange from "../../components/Dashboard/PriceRange";
import Loader from "../../components/Loader";
import ListFilter from "../../components/UI/ListFilter";
import SearchBar from "../../components/Dashboard/SearchBar";
import PageFilter from "../../components/Dashboard/PriceFilter";
import NoNearbyStation from "../../components/UI/NoNearbyStation";

//utils
import { InsertAD } from "../../utils/insertAD";
import { getStationsByProducts } from "../../utils/getStationsByProduct";
import { triggerLocationPrompt } from "../../utils/triggerLocationPrompt";
import { getPriceRange } from "../../utils/getPriceRange";
import { getNearByStations } from "../../utils/getNearByStations";
import {
  filterByAddPrice,
  filterByDistance,
  filterByPrice,
} from "../../utils/FilterStations";
import {
  getUserLocation,
  setCachedAddress,
  setCurrentCoordinate,
  setUserLocation,
} from "../../utils/getUserLocation";
import { storeNearByStations } from "../../utils/storeNearByStations";

//assets
import { BottomSheet } from "react-spring-bottom-sheet";
import "react-spring-bottom-sheet/dist/style.css";
import "react-tooltip/dist/react-tooltip.css";
import { ClipLoader } from "react-spinners";

export default function Dashboard() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  //useselector
  const { closeStations, isCloseStationsLoading, totalRecord } = useSelector(
    (state) => state.closeStations
  );

  const { stationsByState, isStationsByStateLoading } = useSelector(
    (state) => state.stationsByState
  );

  //usestates
  const [stations, setStations] = useState(null);
  const [isLocationPromptAllowed, setIsLocationPromptAllowed] =
    useState("pending");
  const [coordinate, setCoordinate] = useState(null);
  const [searchBasedOnLocation, setSearchBasedOnLocation] = useState(false);
  const [searchedAddress, setSearchedAddress] = useState();
  const [priceRange, setPriceRange] = useState();
  const [numberOfStation, setNumberOfStation] = useState();
  const [selectedProduct, setSelectedProduct] = useState({
    value: "PMS",
    label: "Petrol",
  });
  const [openFilter, setOpenFilter] = useState(false);
  const [showLoadMore, setShowLoadMore] = useState(false);
  const [page, setPage] = useState(1);
  const [isLoadMore, setIsLoadMore] = useState(false);
  const [filterType, setFilterType] = useState("distance");
  const [state] = useState("lagos");
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [searchedLocation, setSearchedLocation] = useState();
  const [totalPage, setTotalPage] = useState();
  const [isAddressSearchLoading, setIsAddressSearchLoading] = useState(false);

  //functions
  //modify station list
  const modifyStationList = (filteredStation) => {
    if (filteredStation) {
      let filteredPrice = getPriceRange(filteredStation, selectedProduct);

      if (filteredPrice.length > 0) {
        setPriceRange({
          highest: Math.max(...filteredPrice),
          lowest: Math.min(...filteredPrice),
        });
        setNumberOfStation(filteredStation?.length);
      } else {
        setStations([]);
        setPriceRange({
          highest: 0,
          lowest: 0,
        });
        setNumberOfStation(0);
      }

      return filteredStation;
    }
  };

  //go to details page
  let goToDetailsPage = (id) => {
    setUserLocation({ ...getUserLocation(), totalRecord });
    navigate(`/details/${id}`);
  };

  //load more
  const loadMore = () => {
    setIsLoadingMore(true);
    let newPage = page + 1;
    setPage(newPage);
    setIsLoadMore(true);

    const params = { ...coordinate, page: newPage };

    dispatch(getCloseStations(params))
      .then((res) => {
        setTotalPage(res?.payload?.pagination?.totalRecord);
      })
      .finally(() => {
        setIsLoadingMore(false); // Set loading state back to false when done
      });
  };

  function onDismiss() {
    setOpenFilter(false);
  }

  //get user's location
  const getStationsCloseToUser = () => {
    function success(position) {
      //Clear list and set address to you
      setStations(null);
      setCachedAddress("You");

      const latitude = position.coords.latitude;
      const longitude = position.coords.longitude;

      if (latitude && longitude) {
        setCoordinate({ lat: latitude, long: longitude });
        setUserLocation({ lat: latitude, long: longitude });
        setCurrentCoordinate({ lat: latitude, long: longitude });
        dispatch(getCloseStations({ lat: latitude, long: longitude })).then(
          (res) => {
            setTotalPage(res?.payload?.pagination?.totalRecord);
          }
        );
        setIsLocationPromptAllowed("no");
      } else {
        dispatch(getStationsByState(state)).then((res) => {
          setTotalPage(res?.payload?.pagination?.totalRecord);
        });
      }
    }

    function error(err) {
      console.log(err);
      setStations(null);
      dispatch(getStationsByState(state)).then((res) => {
        setTotalPage(res?.payload?.pagination?.totalRecord);
      });

      if (err.code === 1) {
        setIsLocationPromptAllowed(false);
      }
      console.log("Unable to retrieve your location");
    }

    triggerLocationPrompt(setIsLocationPromptAllowed, success, error);
  };

  //useeffects

  useEffect(() => {
    getStationsCloseToUser();
  }, []);

  //filter by addprice if url carries add price
  useEffect(() => {
    if (location?.search === "?addprice") {
      setFilterType("add price");
    }
  }, [location]);

  // fetch stations based on a specific co-ordinate
  useEffect(() => {
    if (closeStations) {
      let list;

      if (isLoadMore && stations) {
        list = [...stations, ...closeStations];
        modifyStationList(list);
      } else {
        list = closeStations;
        setStations(
          modifyStationList(
            getStationsByProducts(list, selectedProduct, filterType)
          )
        );
      }

      storeNearByStations(closeStations);
    }
  }, [closeStations]);

  //fetch stations around lagos, happens when user doesn't give access
  useEffect(() => {
    if (stationsByState) {
      setStations(
        modifyStationList(
          getStationsByProducts(stationsByState, selectedProduct, filterType)
        )
      );

      storeNearByStations(stationsByState);
    }
  }, [stationsByState]);

  //Search for stations within the searched stations
  useEffect(() => {
    if (searchedLocation) {
      setIsAddressSearchLoading(true);
      dispatch(getCloseStations(searchedLocation, 1))
        .then((res) => {
          setTotalPage(res?.payload?.pagination?.totalRecord);
        })
        .finally(() => setIsAddressSearchLoading(false));

      setPage(1);
      setStations(null);
    }
  }, [searchedLocation]);

  //Show load more
  useEffect(() => {
    if (selectedProduct.value === "PMS" && totalRecord > stations?.length) {
      setShowLoadMore(true);
    } else {
      setShowLoadMore(false);
    }
  }, [stations]);

  //Refresh button
  useEffect(() => {
    if (searchBasedOnLocation) {
      getStationsCloseToUser();

      setPage(1); //reset load more page to 1
      setSearchBasedOnLocation(false);
      setCachedAddress("You");
    }
  }, [searchBasedOnLocation]);

  //LOAD MORE
  useEffect(() => {
    if (!isCloseStationsLoading && isLoadMore) {
      let newList = closeStations;
      let allList = [...stations, ...newList];

      modifyStationList([...stations, ...newList]);
      // here
      storeNearByStations(allList);
      setStations(allList);
      setIsLoadMore(false);
    }
  }, [isCloseStationsLoading]);

  //filter by product
  useEffect(() => {
    let localStorageStations = getNearByStations();
    if (!localStorageStations) return;
    let filteredStation = getStationsByProducts(
      localStorageStations,
      selectedProduct,
      filterType
    );
    setStations(modifyStationList(filteredStation));
  }, [selectedProduct]);

  //sort stations
  useEffect(() => {
    if (stations) {
      if (filterType === "price") {
        setStations((stations) => filterByPrice(stations, selectedProduct));
      }
      if (filterType === "distance") {
        setStations((stations) => filterByDistance(stations, selectedProduct));
      }
      if (filterType === "add price") {
        setStations((stations) => filterByAddPrice(stations, selectedProduct));
      }
    }
  }, [filterType]);

  //managing loading state
  useEffect(() => {
    if (isLocationPromptAllowed !== "pending") {
      if (!isLoadingMore) {
        if (!isLocationPromptAllowed && !isStationsByStateLoading) {
          setIsLoading(false);
        }

        if (!isLocationPromptAllowed && isStationsByStateLoading) {
          setIsLoading(true);
        }

        if (isLocationPromptAllowed && !isCloseStationsLoading) {
          setIsLoading(false);
        }

        if (isLocationPromptAllowed && isCloseStationsLoading) {
          setIsLoading(true);
        }
      }
    }
  }, [
    stations,
    isCloseStationsLoading,
    isStationsByStateLoading,
    isLocationPromptAllowed,
    isLoadingMore,
  ]);

  // console.log(isLoading, "kjdf");
  // console.log(isAddressSearchLoading, "actual");

  return (
    <>
      {(isLoading || isAddressSearchLoading) && <Loader />}

      {!isLoading && !isAddressSearchLoading && (
        <section className={`w-[100%] mx-auto `}>
          {/* MENU */}
          <section className="fixed top-0 left-0 z-30 w-full bg-white">
            {!isLocationPromptAllowed && (
              <div className="bg-orange-600 w-[90%] mt-2 mx-auto text-white text-center text-xs py-2 px-2 rounded">
                {" "}
                Allow app access your location to find stations near you
              </div>
            )}

            <section className="w-[100%] absolute bg-white   z-30 pb-3">
              <section className="w-[90%]  mx-auto">
                <SearchBar
                  setCoordinate={setCoordinate}
                  setSearchBasedOnLocation={setSearchBasedOnLocation}
                  setSearchedAddress={setSearchedAddress}
                  setSearchedLocation={setSearchedLocation}
                />

                <PriceRange
                  stations={stations}
                  onDismiss={onDismiss}
                  priceRange={priceRange}
                  numberOfStation={numberOfStation?.toLocaleString()}
                  setSearchBasedOnLocation={setSearchBasedOnLocation}
                />

                <PageFilter
                  selectedProduct={selectedProduct}
                  setSelectedProduct={setSelectedProduct}
                  setOpenFilter={setOpenFilter}
                  filterType={filterType}
                />
              </section>
            </section>
          </section>

          {/* STATION LIST */}
          <section
            className={` w-[90%]    mx-auto ${
              !isLocationPromptAllowed
                ? "mt-[98%] lg:mt-[28%] md:mt-[48%] "
                : " lg:mt-[24%] md:mt-[33%] mt-[88%]"
            }   `}
          >
            <div className="mt-4 mb-8">
              {stations && (
                <div className="flex flex-col">
                  {stations &&
                    InsertAD(stations, selectedProduct, goToDetailsPage)}

                  {showLoadMore && (
                    <div className="flex justify-center mt-5">
                      <button
                        onClick={loadMore}
                        className={`px-5 py-2 text-xs flex items-center bg-[var(--orange600)] rounded text-white `}
                      >
                        {isCloseStationsLoading ? (
                          <div className="flex items-center justify-center">
                            <p className="mr-2">Loading</p>
                            <ClipLoader size={12} color="white" />
                          </div>
                        ) : (
                          "Load More"
                        )}
                      </button>
                    </div>
                  )}
                </div>
              )}

              {stations && stations?.length === 0 && <NoNearbyStation />}
            </div>
          </section>
        </section>
      )}

      <BottomSheet
        className="absolute bottom-0 z-30"
        open={openFilter}
        onDismiss={onDismiss}
        defaultSnap={({ maxHeight }) => maxHeight / 2}
      >
        <ListFilter setFilterType={setFilterType} onDismiss={onDismiss} />
      </BottomSheet>
    </>
  );
}
