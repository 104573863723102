import React from "react";
import notFound from "../../assets/svg/404.svg";
import { NavLink } from "react-router-dom";

export default function PageNotFound() {
  return (
    <section className="flex flex-col h-screen items-center justify-center ">
      <section className="">
        <div className="flex justify-center">
          <img src={notFound} alt="Page not found" />
        </div>

        <div className="mt-10 text-center">
          <h3 className="font-semibold text-2xl">Page not found</h3>
          <p className="text-sm mt-3">
            Sorry, we are unable to find that <br />
            page.
          </p>

          <NavLink to="/dashboard">
            <button
              className={`  bg-[var(--orange600)]
             my-10  mt-5 mx-auto text-sm py-5 text-white w-[100%] rounded-lg `}
            >
              Let's take you Home
            </button>
          </NavLink>
        </div>
      </section>
    </section>
  );
}
