export const sharePriceWidget = (e, pathname) => {
  if (navigator.share) {
    let url = "https://www.fuelprice.ng/";
    // if (pathname.includes("app.")) {
    //   url = "https://app.fuelprice.ng/";
    // } else {
    //   url = "https://www.fuelprice.ng/";
    // }

    navigator
      .share({
        title: "Fuelprice.ng",
        url: url,
        text: "Hey! Just used the Fuel Price app https://www.fuelprice.ng/ to find the current fuel prices in my area. It's super handy and saved me time and money.Give it a try.",
      })
      .then(() => {
        console.log("Thanks for sharing");
      })
      .catch(console.error);
  } else {
    e.target.setAttribute(
      "onclick",
      'Android.onShareClicked("https://app.fuelprice.ng/")'
    );
    // alert("doesn't support");
  }
};
