export const CustomImg = (companyName) => {
  let splitCompanyName = companyName?.split(" ");
  let firstLetter = splitCompanyName?.[0]?.[0];
  let secondLetter = splitCompanyName?.[1]?.[0];

  return (
    <div className="bg-[var(--orange600)] uppercase text-center text-white py-2 text-sm font-black rounded-full">
      {companyName && (
        <p>
          {firstLetter}
          {secondLetter}
        </p>
      )}

      {!companyName && <p>NC</p>}
    </div>
  );
};
