export const getUserLocation = () => {
  let userLocation = localStorage.getItem("userLocation");

  if (userLocation && userLocation !== "") {
    return JSON.parse(userLocation);
  } else {
    return null;
  }
};

export const setUserLocation = (coordinate) => {
  localStorage.setItem("userLocation", JSON.stringify(coordinate));
};

export const setCurrentCoordinate = (coordinate) => {
  localStorage.setItem("currentCoordinate", JSON.stringify(coordinate));
};

export const getCurrentCoordinate = () => {
  return JSON.parse(localStorage.getItem("currentCoordinate"));
};


export const setCachedAddress = (address) => {
  localStorage.setItem("cachedAddress", JSON.stringify(address));
};

export const getCachedAddress = () => {
  return JSON.parse(localStorage.getItem("cachedAddress"));
};

