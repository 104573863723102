import React, { useEffect, useState } from "react";
import {
  NavLink,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { ReportStationPriceApi } from "../../services";
import { InfoToast, SuccessToast } from "../../components";
import AdSense from "react-adsense";

//assets
import backArrow from "../../assets/svg/backArrow.svg";
import edit from "../../assets/svg/edit.svg";

//components
import ReportBanner from "../../components/ReportPrice/ReportBanner";

export default function ReportPrice() {
  const navigate = useNavigate();
  const { state } = useLocation();
  let [searchParams] = useSearchParams();
  const branchId = searchParams.get("q");

  //usestates
  const [isLoading, setIsLoading] = useState(false);
  const [anonymous, setAnonymous] = useState(false);
  const [allProductPrices, setAllProductPrices] = useState({
    PMS: {},
    AGO: {},
    LPG: {},
    DPK: {},
  });
  const [formData, setFormData] = useState({
    PMS: 0,
    AGO: 0,
    DPK: 0,
    LPG: 0,
  });

  //http requests
  const reportPrice = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const productPrices = Object.entries(formData).map((e) => ({
      productName: e[0].toUpperCase(),
      price: Number(e[1]),
    }));

    const payload = {
      anonymous,
      branchId,
      productPrices,
    };

    try {
      const res = await ReportStationPriceApi(payload);
      SuccessToast(res.message);

      const newPath = `/details/${branchId}`;
      const state = { from: "/report-price" };

      navigate(newPath, { state });
    } catch (err) {
      console.log(err);
      InfoToast();
    } finally {
      setIsLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setFormData({ ...formData, [name]: value });
  };

  const getProductPrices = (detail) => {
    const { products } = detail;

    const payload = products.map(({ name, price }) => ({
      name,
      price: price < 0 ? 0 : price,
      slug: name.toLowerCase(),
    }));

    payload.forEach((item) => {
      setAllProductPrices((prevState) => ({
        ...prevState,
        [item.name]: { ...item },
      }));

      setFormData((prevState) => ({
        ...prevState,
        [item.name]: item.price,
      }));
    });
  };

  //useeffects
  useEffect(() => {
    getProductPrices(state?.stationDetail);
  }, []);

  return (
    <section className="mx-auto w-[90%] relative">
      <NavLink
        to={`/details/${branchId}`}
        state={{ from: "/report-price" }} // Pass the state object
      >
        <img
          src={backArrow}
          alt="Go back"
          className="absolute top-2 left-1 w-7"
        />
      </NavLink>

      <section className="mt-5">
        <h1 className="text-center pt-2 text-base font-bold text-[var(--grey600)]">
          Report price
        </h1>
      </section>

      <ReportBanner stationDetail={state?.stationDetail} />
      <section className="mt-8">
        <form onSubmit={reportPrice}>
          <div className="mb-6">
            <label className="block text-sm font-semibold text-[var(--grey600)] pl-1">
              Petrol Price
            </label>
            <div className="outline-none py-3 mt-2 pl-4 w-full flex justify-between rounded-lg bg-[var(--inputGray)]">
              <input
                name={allProductPrices?.PMS.name ?? "PMS"}
                placeholder={allProductPrices?.PMS.price ?? 0}
                className="bg-transparent h-[100%] focus:outline-[var(--inputGray)] placeholder:text-[var(--black10)] text-xs font-bold w-[70%] focus:outline-none focus:border- 
                slate-500 focus:ring-slate-500"
                onChange={handleInputChange}
                value={formData.PMS.price}
                type="number"
                step="any"
              />
              <img className="mr-6" src={edit} alt="edit price" />
            </div>
          </div>

          <div className="mb-6">
            <label className="block text-sm font-semibold text-[var(--grey600)] pl-1">
              Diesel Price
            </label>
            <div className="outline-none py-3 mt-2 pl-4 w-full flex justify-between rounded-lg bg-[var(--inputGray)]">
              <input
                name={allProductPrices?.AGO.name ?? "AGO"}
                placeholder={allProductPrices?.AGO.price ?? 0}
                className="bg-transparent h-[100%] focus:outline-[var(--inputGray)] placeholder:text-[var(--black10)] text-xs font-bold w-[70%] focus:outline-none focus:border- 
                slate-500 focus:ring-slate-500"
                onChange={handleInputChange}
                value={formData.AGO.price}
                type="number"
                step="any"
              />
              <img className="mr-6" src={edit} alt="edit_price" />
            </div>
          </div>

          <div className="mb-6">
            <label className="block text-sm font-semibold text-[var(--grey600)] pl-1">
              Kerosene Price
            </label>
            <div className="outline-none py-3 mt-2 pl-4 w-full flex justify-between rounded-lg bg-[var(--inputGray)]">
              <input
                name={allProductPrices?.DPK.name ?? "DPK"}
                placeholder={allProductPrices?.DPK.price ?? 0}
                className="bg-transparent h-[100%] focus:outline-[var(--inputGray)] placeholder:text-[var(--black10)] text-xs font-bold w-[70%] focus:outline-none focus:border- 
                slate-500 focus:ring-slate-500"
                onChange={handleInputChange}
                value={formData.DPK.price}
                type="number"
                step="any"
              />
              <img className="mr-6" src={edit} alt="edit price" />
            </div>
          </div>

          <div className="mb-6">
            <label className="block text-sm font-semibold text-[var(--grey600)] pl-1">
              Gas Price
            </label>
            <div className="outline-none py-3 mt-2 pl-4 w-full flex justify-between rounded-lg bg-[var(--inputGray)]">
              <input
                name={allProductPrices?.LPG.name ?? "LPG"}
                placeholder={allProductPrices?.LPG.price ?? 0}
                className="bg-transparent h-[100%] focus:outline-[var(--inputGray)] placeholder:text-[var(--black10)] text-xs font-bold w-[70%] focus:outline-none focus:border- 
                slate-500 focus:ring-slate-500"
                onChange={handleInputChange}
                value={formData.LPG.price}
                type="number"
                step="any"
              />
              <img className="mr-6" src={edit} alt="edit price" />
            </div>
          </div>

          <div className="pt-2">
            <label className="flex items-center gap-x-4 text-sm text-[#000] font-normal">
              <input
                type="checkbox"
                checked={anonymous}
                onChange={(e) => {
                  setAnonymous(e.target.checked);
                }}
              />
              Post as anonymous
            </label>
          </div>
          <AdSense.Google
            client={process.env.REACT_APP_ADSENSE_CLIENT}
            slot="8156775046"
            style={{ display: "block" }}
            layout="in-article"
            format="fluid"
          />
          {/* <AdsComponent dataAdSlot="7534830653" /> */}
          <button
            className="my-10 mx-auto text-xs py-4 text-white w-full rounded-lg bg-[var(--orange600)] font-semibold"
            type="submit"
          >
            {isLoading ? "Submitting..." : "Submit Price"}
          </button>
        </form>
      </section>
    </section>
  );
}
