import axios from "../../lib/axiosInterceptor";

/**
 * Description
 * Update report station price
 * @param {any} payload
 * @returns {any}
 */
export const ReportStationPriceApi = async (payload) => {
  try {
    const url = "/Station/ReportPrice";
    const res = await axios.post(url, payload);

    return res.data;
  } catch (err) {
    throw err;
  }
};

/**
 * Description
 * Get All Stations in user's location
 * @method GET
 * @returns {any}
 */
export const GetStationApi = async () => {
  try {
    const url = "/Station";
    const res = await axios.get(url);

    return res.data;
  } catch (err) {
    throw err;
  }
};
