export const getProductPrice = (data, productName)=>{

    if(data){
       let productObject =  data?.products?.filter(
            (product) => product.name?.toLowerCase() === productName
          );

          return productObject?.[0]?.price

    }
}