import React from "react";
import Select from "react-select";

import filterIcon from "../../assets/svg/filter.svg";
import { getCachedAddress } from "../../utils/getUserLocation";

export default function PageFilter({
  selectedProduct,
  setSelectedProduct,
  setOpenFilter,
  filterType,
}) {
  // variables
  let cachedAddress = getCachedAddress();
  const options = [
    { value: "PMS", label: "Petrol" },
    { value: "AGO", label: "Diesel" },
    { value: "DPK", label: "Kerosene" },
    { value: "LPG", label: "Gas" },
  ];

  return (
    <section className={`mt-2`}>
      <div className={`flex items-center justify-center justify-between`}>
        <Select
          defaultValue={selectedProduct}
          onChange={setSelectedProduct}
          options={options}
          className={`border-0 text-[var(--black500)] font-semibold text-sm`}
        />

        <div className={`mr-2 flex `} onClick={() => setOpenFilter(true)}>
        <p className="text-xs mr-2   text-[var(--orange600)] font-semibold py-1">
          Sorted by {filterType}
        </p>
          <img src={filterIcon} alt="Filter icon" />
        </div>
      </div>

      <div className=" w-[100%] flex flex-col lg:flex-row justify-between mt-4 lg:items-center">
        <p className="text-xs font-semibold ">
          {`${selectedProduct?.label} price around  ${
            cachedAddress ? cachedAddress : "Lagos"
          }`}
        </p>


      </div>
    </section>
  );
}
