import React, { useEffect, useState } from "react";
import compassactive from "../../assets/svg/compassactive.svg";
import compass from "../../assets/svg/compass.svg";
import stationIcon from "../../assets/svg/stationIcon.svg";
import activeStationIcon from "../../assets/svg/activeStationIcon.svg";
import more from "../../assets/svg/more.svg";
import activemore from "../../assets/svg/activemore.svg";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { Modal } from "antd";
import ShareButton from "../ShareButton";
import { getNearByStations } from "../../utils/getNearByStations";

export default function NavBar() {
  const location = useLocation();

  //usestates
  const [path, setPath] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);

  //variables
  let active = "font-bold text-[var(--orange600)] text-xs";
  let notActive = "font-semibold text-xs";
  let shareContent = ` Hey! Just used the Fuel Price app to find the current fuel prices in my area. It's super handy and saved me time and money .Give it a try. #fuelprice  https://www.fuelprice.ng/`;

  //functions
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    localStorage.setItem("modalShown", JSON.stringify(true));
  };

  //useeffefcts

  useEffect(() => {
    let stations = getNearByStations();
    const openShareModal = setInterval(() => {
      let shownModal = JSON.parse(localStorage.getItem("modalShown"));

      if (stations && !shownModal) {
        showModal();
      }

      if (shownModal) {
        localStorage.setItem("modalShown", JSON.stringify(false));
      }
    }, 70000);

    return () => {
      clearInterval(openShareModal);
    };
  }, [isModalOpen]);

  useEffect(() => {
    setPath(location.pathname);
  }, [location]);

  return (
    <section className="flex justify-between py-4 w-[85%] mx-auto ">
      <NavLink to="/dashboard" state={{ from: location?.pathname }}>
        <div className="flex justify-center mt-[2px] mb-2">
          {path?.toLowerCase() === "/dashboard" ? (
            <img src={compassactive} alt="Dashboard link" />
          ) : (
            <img src={compass} alt="Dashboard link" />
          )}
        </div>
        <p
          className={`${
            path?.toLowerCase() === "/dashboard" ? active : notActive
          }`}
        >
          Find Price
        </p>
      </NavLink>

      <NavLink to="/stations">
        <div className="flex justify-center mb-2">
          {path?.toLowerCase() === "/suggest-station" ||
          path?.toLowerCase().includes("/details") ||
          path?.toLowerCase() === "/stations" ? (
            <img src={activeStationIcon} alt="Stations link" />
          ) : (
            <img src={stationIcon} alt="Stations link" />
          )}
        </div>
        <p
          className={`${
            path?.toLowerCase() === "/suggest-station" ||
            path?.toLowerCase().includes("/details") ||
            path?.toLowerCase() === "/stations"
              ? active
              : notActive
          }`}
        >
          Enroll Stations
        </p>
      </NavLink>

      <div>
        <NavLink to="/profile">
          <div className="flex justify-center mb-2">
            {path?.toLowerCase() === "/profile" ? (
              <img src={activemore} alt="more link" />
            ) : (
              <img src={more} alt="more link" />
            )}
          </div>
          <p
            className={`${
              path?.toLowerCase() === "/profile" ? active : notActive
            }`}
          >
            More
          </p>
        </NavLink>
      </div>

      <Modal
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        centered
        width={320}
        footer={false}
        title="Share"
      >
        <ShareButton url={shareContent} />
      </Modal>
    </section>
  );
}
