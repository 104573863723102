import React, { useEffect, useState } from "react";
import axios from "../../lib/axiosInterceptor";
import Loader from "../Loader";
import noSug from "../../assets/svg/noSug.svg";
import { getDeviceHeight } from "../../utils/getDeviceHeight";
import SuggestionCard from "./SuggestionCard";

export default function MySuggestions({ mySuggestions }) {
  let deviceHeight = getDeviceHeight;
  return (
    <section style={{ height: deviceHeight }}>
      <section>
        {mySuggestions &&
          mySuggestions.length > 1 &&
          mySuggestions?.map((station) => {
            return <SuggestionCard key={station.id} suggestion={station} />;
          })}

        {mySuggestions && mySuggestions.length < 1 && (
          <div className="h-[100vh] ">
            <p className="flex flex-col text-center font-semibold justify-center items-center h-[50%]">
              <img
                src={noSug}
                alt="You have no suggestions"
                className="mb-6 w-[5.5rem] h-[5.375rem]"
              />
              You don’t have any suggestion yet.
            </p>
          </div>
        )}
      </section>
    </section>
  );
}
