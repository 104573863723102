import React, { useEffect, useState } from "react";
import { Link, NavLink, useLocation, useParams } from "react-router-dom";

// import AdSense from "react-adsense";
import { getNearByStations } from "../../utils/getNearByStations";
import { storeNearByStations } from "../../utils/storeNearByStations";

//assets
import backArrow from "../../assets/svg/backArrow.svg";
import ad2 from "../../assets/svg/Fuel differently.jpg";
import ad3 from "../../assets/svg/Fueling Email.jpg";
import ad4 from "../../assets/svg/fuelSmart.jpg";
import ad5 from "../../assets/svg/Easy.jpg";

//components
import Banner from "../../components/Details/Banner";
import StationPrice from "../../components/Details/StationPrice";
import Loader from "../../components/Loader";
import { useDispatch } from "react-redux";
import { fetchStationDetails } from "../../store/Slices/getStationDetails";

export default function Details() {
  const dispatch = useDispatch();
  const { stationId } = useParams();

  const location = useLocation();
  const prevRoute = location.state?.from;

  //usestates
  const [stationDetails, setStationsDetails] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [stationPrice, setStationPrice] = useState();
  const [adIndex, setAdIndex] = useState();

  //custom variables
  let adImgs = [ad3, ad4, ad5, ad2];

  //custom function
  const getStationDetails = () => {
    setIsLoading(true);

    let stationList = getNearByStations();

    if (!stationList) return;
    setStationsDetails(
      stationList?.filter((station) => stationId === station.id)?.[0]
    );

    setIsLoading(false);
  };

  useEffect(() => {
    setAdIndex(Math.floor(Math.random() * 4));
  }, []);

  //useeffects
  useEffect(() => {
    if (prevRoute?.includes("report-price")) {
      setIsLoading(true);
      dispatch(fetchStationDetails(stationId))
        .then(({ payload }) => {
          setStationsDetails(payload);
          //swap updated price change into cached data
          let cachedNearByStations = getNearByStations();
          let stationIndex = cachedNearByStations.findIndex((station) => {
            return station?.id === stationId;
          });
          cachedNearByStations[stationIndex].products = payload.products;
          localStorage.setItem("isPriceUpdated", JSON.stringify(true));
          storeNearByStations(cachedNearByStations);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      getStationDetails();
    }
  }, [stationId]);

  console.log(adIndex);

  return (
    <>
      {isLoading && <Loader />}

      {!isLoading && (
        <section className="mx-auto w-[90%] relative">
          <NavLink to="/dashboard" state={{ from: "/details" }}>
            <img
              src={backArrow}
              alt="Go back"
              className="absolute top-2 left-1 w-[1.875rem]"
            />
          </NavLink>

          <section className="mt-5">
            <h1 className="text-center pt-2 text-base font-bold text-[var(--black500)]">
              Station details
            </h1>
          </section>

          <Banner details={stationDetails} />

          <StationPrice details={stationDetails} price={stationPrice} />

          <div className={`w-full flex justify-center my-4`}>
            <Link
              to={`/report-price?q=${stationId}`}
              state={{ stationDetail: stationDetails }}
            >
              <button
                className={` text-base font-semibold px-8 text-[var(--orange600)]  py-[0.5rem] rounded-full bg-[var(--orange50)] `}
              >
                Report prices
              </button>
            </Link>
          </div>

          {/* <AdSense.Google
            client={process.env.REACT_APP_ADSENSE_CLIENT}
            slot="8156775046"
            style={{ display: "block" }}
            layout="in-article"
            format="fluid"
          /> */}

          <section className={`my-4 w-full`}>
            <NavLink to="https://www.remis.africa/fleet" target="_blank">
              <img
                src={adImgs[adIndex]}
                alt="banner"
                className="w-full object-contain lg:h-[12.5rem] md:h-[100px] rounded-lg"
              />
            </NavLink>
          </section>

          {/* <AdsComponent dataAdSlot="7534830653"/> */}
        </section>
      )}
    </>
  );
}
