import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isServerError: false,
  isNetworkError: false,
  error: null,
};

const errorHandlingSlice = createSlice({
  name: "errorHandling",
  initialState,
  reducers: {
    setServerError(state, { payload }) {
      state.isServerError = payload;
    },
    setNetworkError(state, { payload }) {
      state.isNetworkError = payload;
    },
  },
});

export const { setServerError, setNetworkError } = errorHandlingSlice.actions;
export default errorHandlingSlice.reducer;
