import { NavLink } from "react-router-dom";
import StationCard from "../components/Dashboard/StationCard";

import ad2 from "../assets/svg/Fuel differently.jpg";
import ad3 from "../assets/svg/Fueling Email.jpg";
import ad4 from "../assets/svg/fuelSmart.jpg";
import ad5 from "../assets/svg/Easy.jpg";
// import AdsComponent from "./AdsComponent";
// import AdSense from "react-adsense";
// import { setUserLocation } from "./getUserLocation";

export const InsertAD = (
  stations,
  selectedProduct,
  goToDetailsPage,
  pathname,
  showModal,

) => {
 
  if (!stations) return null;

  const newArray = [];
  let dynamicNum;
  if (stations?.length < 2) {
    dynamicNum = 1;
  } else {
    dynamicNum = 3;
  }



  stations.forEach((station, index) => {
    let stationList;
    
    if (pathname !== "/") {
      stationList = (
        <div key={index} onClick={() => goToDetailsPage(station?.id)}>
          <StationCard station={station} type={selectedProduct?.value} />
        </div>
      );
    } else {
      stationList = (
        <div onClick={showModal}>
          <StationCard station={station} type={selectedProduct?.value} />
        </div>
      );
    }
    const renderedObjects = stationList;
    newArray.push(renderedObjects);

    let adImgs = [ad3, ad4, ad5, ad2];

    if ((index + 1) % dynamicNum === 0 && index !== stations.length - 1) {
      let adImgIndex = Math.floor((index / dynamicNum) % adImgs.length);

      let adImg = adImgs[adImgIndex];
      let rand = Math.random();

      newArray.push(
        <NavLink to="https://www.remis.africa/fleet" target="_blank" key={rand}>
          <section className="h-[7rem]  w-full rounded-lg my-4 mb-6">
            <img
              src={adImg}
              className=" w-full  rounded-lg lg:h-[9rem] md:h-[9rem]  object-contain"
              alt="Remis Ad"
            />
            {/* <AdSense.Google
            client={process.env.REACT_APP_ADSENSE_CLIENT}
            slot="8156775046"
            style={{ width: 250, height: 250 }}
            format=""
            layout="in-article"
          /> */}
          </section>
        </NavLink>

        // <AdSense.Google

        //   style={{ display: "block", height: 150 }}
        //   // layout="in-article"
        //   // format="fluid"
        // />
      );
    }
  });

  return newArray;
};
