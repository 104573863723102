import React from "react";
// import line from "../../assets/svg/line.svg";
import arrowUp from "../../assets/svg/arrowUp.svg";
import { breakNumber } from "../../utils/breakNumber";
// import ShareButton from "../ShareButton";
import { useLocation } from "react-router-dom";
import { sharePriceWidget } from "../../utils/sharePriceWidget";
import { FiRefreshCcw } from "react-icons/fi";
// import { Modal } from "antd";

export default function PriceRange({
  priceRange,
  numberOfStation,
  setSearchBasedOnLocation,
  stations,
  onDismiss,
}) {
  const { pathname } = useLocation();

  //variables
  let lowest = breakNumber(priceRange?.lowest);
  let highest = breakNumber(priceRange?.highest);

  //PROMPT SHARE MODAL
  // useEffect(() => {
  //   let shownModal = JSON.parse(localStorage.getItem("modalShown"));

  //   if (stations?.length > 0 && !shownModal) {
  //     console.log("yhhh");
  //     showModal();
  //     onDismiss();
  //   }
  // }, [stations]);

  return (
    <section className={` mt-2 items-center`}>
      <div className={`bg-[var(--black700)]  rounded-xl py-5 px-4`}>
        <div className={`flex justify-between items-center `}>
          <div>
            <h6 className={`text-[var(--grey400)] text-xs`}>Lowest price</h6>
            {lowest && (
              <h1 className={`text-white text-base font-semibold mt-2`}>
                <sup>NGN</sup> {lowest?.[0]}.<sup>{lowest?.[1]}</sup>{" "}
              </h1>
            )}

            {!lowest && (
              <h1 className={`text-white text-base font-semibold mt-2`}>---</h1>
            )}
          </div>

          <div>
            <h6 className={`text-[var(--grey400)] text-xs`}>Highest price</h6>
            {highest && (
              <h1 className={`text-white text-base font-semibold mt-2`}>
                <sup>NGN</sup> {highest?.[0]}.<sup>{highest?.[1]}</sup>{" "}
              </h1>
            )}

            {!highest && (
              <h1 className={`text-white text-base font-semibold mt-2`}>---</h1>
            )}
          </div>
        </div>

        <div className="flex items-center my-3 gap-x-1 ">
          <div className="w-2 h-2 bg-gray-500 rounded-full"></div>
          <div className="border-t border-gray-500 border-dashed w-[97%] "></div>
          <span className="w-2 h-2 bg-gray-500 rounded-full"></span>
          {/* <img src={line} alt="price range" /> */}
        </div>

        <div className={`flex justify-between items-center `}>
          <div
            className="flex items-center cursor-pointer"
            onClick={() => setSearchBasedOnLocation(true)}
          >
            <FiRefreshCcw color="#e45618" size={13} />
            <p className={`text-[var(--orange600)] ml-1 text-sm text-center`}>
              {numberOfStation} stations nearby
            </p>
          </div>

          <div
            className={`flex justify-between underline underline-offset-4  items-center text-white `}
          >
            <button
              className="mr-1 text-xs"
              onClick={(e) => sharePriceWidget(e, pathname)}
            >
              Share price
            </button>

            {/* <ShareButton/> */}
            <img src={arrowUp} alt="price range" />
          </div>
        </div>
      </div>
    </section>
  );
}
