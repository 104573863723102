import React from "react";

import logo from "../../assets/svg/logo.svg";

import { getDeviceHeight } from "../../utils/getDeviceHeight";

export default function ErrorBoundaryWrapper({ error }) {
  let deviceHeight = getDeviceHeight;

  return (
    <section
      style={{ height: deviceHeight }}
      className=" flex flex-col items-center justify-center h-screen "
    >
      <div className="flex justify-center mt-20 mb-10">
        <img src={logo} alt="Fuelprice logo" className="w-[2.9rem]" />
      </div>
      <p className="text-center  w-[80%] mx-auto text-sm">
        {" "}
        An error occured, the team is on it. Kindly head back home while we fix
        it
      </p>

      <button
        onClick={() => window.location.replace("/stations")}
        className={`  bg-[var(--orange600)]
             my-10  mt-5 mx-auto text-sm p-3 text-white w-[50%] rounded`}
      >
        Let's take you Home
      </button>
    </section>
  );
}
