import {
  filterByAddPrice,
  filterByDistance,
  filterByPrice,
} from "./FilterStations";

export const getStationsByProducts = (
  allStations,
  selectedProduct,
  filterType
) => {
  if (filterType === "price") {
    return filterByPrice(allStations, selectedProduct);
  }

  if (filterType === "distance") {
    return filterByDistance(allStations, selectedProduct);
  }

  if (filterType === "add price") {
    return filterByAddPrice(allStations, selectedProduct);
  }
};
