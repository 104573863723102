import axios from "../../lib/axiosInterceptor";
import { distanceRange, loadPageSize } from "../../utils/pageSizeDistance";

export const _getCloseStations = async (params) => {
  console.log(params);
  return axios.get(
    `/station?latitude=${params?.lat}&longitude=${
      params?.long
    }&distance=${distanceRange}&page=${
      params?.page ? params.page : 1
    }&pageSize=${params?.pageSize ? params?.pageSize : loadPageSize}`
  );
};

export const _getStationsByState = async (state) => {
  return axios.get(`/Station/ByState/${state}?pageSize=100`);
};

export const _getUserProfile = async () => {
  return axios.get(`/Account/MyProfile`);
};

export const _fetchStationDetails = async (id) => {
  return axios.get(`/Station/ById/${id}`);
};

export const _deleteUserAcct= async () => {
  return axios.post(`/Account/DeleteAccount`);
};
