import React from "react";
import gift from "../../../assets/svg/gift.svg";
import filter from "../../../assets/svg/filter.svg";
import totalE from "../../../assets/svg/totalE.svg";

const historyData = [
  {
    id: 1,
    name: "Total plc",
    price: 5000,
    position: "1st",
    gas: "AGO",
    imageSrc: totalE,
    price: "NGN568.99",
    points: "1 point",
    bg: "#F6F6F6",
    address: "259 herbert Macauley way",
    time: "3hrs ago",
  },
  {
    id: 2,
    name: "Total plc",
    price: 3000,
    position: "2nd",
    gas: "DPK",
    imageSrc: totalE,
    price: "NGN568.99",
    bg: "#F6F6F6",
    points: "Invalid",
    address: "259 herbert Macauley way",
    time: "3hrs ago",
  },
  {
    id: 3,
    name: "Total plc",
    price: 2800,
    position: "3rd",
    imageSrc: totalE,
    gas: "PMS",
    bg: "#F6F6F6",
    price: "NGN568.99",
    points: "1 point",
    address: "259 herbert Macauley way",
    time: "3hrs ago",
  },
  {
    id: 4,
    name: "Total plc",
    price: 2800,
    gas: "PMS",
    position: "3rd",
    price: "NGN568.99",
    imageSrc: totalE,
    bg: "#F6F6F6",
    points: "1 point",
    address: "259 herbert Macauley way",
    time: "3hrs ago",
  },

  {
    id: 4,
    name: "Total plc",
    price: "NGN568.99",
    position: "3rd",
    gas: "AGO",
    imageSrc: totalE,
    points: "1 point",
    bg: "#F6F6F6",
    address: "259 herbert Macauley way",
    time: "3hrs ago",
  },
];

const MyPoints = ({ rewards }) => {
  return (
    <section>
      <div className="flex flex-col items-center justify-center py-2 font-custom">
        <div className="py-8">
          <img src={gift} className="w-[80px]" alt="" />
        </div>
        <p className="text-[#252525] font-bold text-base pt-2">
          {rewards.totalPoints}
        </p>
        <p className="text-[#252525] font-bold font-custom text-base pb-2 items-center flex">
          Points
        </p>
      </div>

      <section>
        <div className="flex items-center justify-between">
          <div className="leaderboard--heading text-[#101010] font-custom text-base font-bold">
            History
          </div>
          <img src={filter} alt="" />
        </div>
        <section className="my-4">
          <p className="my-10 text-sm font-semibold text-center">
            You have no history at the moment
          </p>
          {/* <div className="w-full space-y-1 rounded-lg">
            {historyData.map(
              ({
                id,
                bg,
                imageSrc,
                name,
                price,
                points,
                address,
                time,
                gas,
              }) => (
                <div
                  key={id}
                  className={`flex justify-between items-center mb-4 rounded-lg px-3 py-4 shadow-sm bg-[#FDFAFA] `}
                >
                  <div className="flex items-center w-[75%]">
                    <div className="">
                      <img src={imageSrc} alt="" className="w-10 h-10 mr-2 " />
                    </div>
                    <div>
                      <div className="text-[#252525] font-bold mb-1 text-sm">
                        {name}
                      </div>
                      <div className="flex items-center justify-between">
                        <p className="text-[#515151] text-xs font-normal pr-3 mb-1 text-xs">
                          {address}
                        </p>
                      </div>
                      <div>
                        <span
                          className={`font-Quiet_sans text-xs text-center ${
                            points == "1 point"
                              ? "text-[#B24312]"
                              : "text-[#A9A9A9]"
                          }`}
                        >
                          {points}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div>
                    <p className="text-[#222222] font-bold text-sm mb-1">
                      {price}{" "}
                    </p>
                    <p className="text-[#888484] font-medium text-xs ">{gas}</p>
                    <p className="text-[#888484] text-xs font-normal ">
                      {time}
                    </p>
                  </div>
                </div>
              )
            )}
          </div> */}
        </section>
      </section>
    </section>
  );
};

export default MyPoints;
