import React from "react";

import { convertDistance } from "../../utils/convertDistance";
import { getUserLocation } from "../../utils/getUserLocation";

//assets
import send from "../../assets/svg/send.svg";
import verified from "../../assets/svg/verify.svg";
import { CustomImg } from "../../utils/customImg";

export default function Banner({ details }) {
  let googleMapsLink;
  const userLocation = getUserLocation();

  if (userLocation) {
    googleMapsLink = `https://www.google.com/maps/dir/?api=1&origin=${userLocation.lat},${userLocation.long}&destination=${details?.latitude},${details?.longitude}`;
  } else {
    googleMapsLink = `https://www.google.com/maps/dir/?api=1&destination=${details?.latitude},${details?.longitude}`;
  }

  const goToMap = (e) => {
    try {
      e.preventDefault();
      window.open(googleMapsLink, "_blank");
      window.webkit.messageHandlers.mapUrl.postMessage(googleMapsLink);
    } catch (err) {
      console.log(err);
    }

  
  };

  return (
    <section className={`bg-[var(--inputGray)] rounded-lg mt-5 py-4 `}>
      <div className={`w-full flex items-center justify-center relative`}>
        {details?.companyLogoUrl && (
          <img
            src={verified}
            alt="Popular stations"
            className="absolute bottom-[-6px] w-[1.25rem] z-20"
          />
        )}

        {details?.companyLogoUrl ? (
          <img
            src={details?.companyLogoUrl}
            alt="company logo"
            className={`object-contain border-4 border-[var(--grey300)] rounded-full w-[4rem] h-[4rem]`}
          />
        ) : (
          <div className="w-[4rem] rounded-full ">
            {CustomImg(details?.companyName)}
          </div>
        )}
      </div>

      <div className={`w-[90%] mx-auto mt-5`}>
        <div className={`flex justify-between`}>
          <h2 className={`font-semibold text-sm`}>{details?.companyName}</h2>

          <p className={`text-[var(--orange700)] text-xs font-semibold`}>
            {convertDistance(details?.distanceToTarger)}
          </p>
        </div>

        <p
          className={`text-xs font-semibold text-left mt-1 text-[var(--black400)]`}
        >
          {details?.city?.toUpperCase()}, {details?.state?.toUpperCase()} STATE
        </p>

        <p
          className={`text-xs font-semibold text-left mt-1 text-[var(--black400)]`}
        >
          {details?.street}
        </p>
      </div>

      <div className={`flex justify-center`}>
        {/* <NavLink
          to={googleMapsLink}
          target="_blank"
          className={` flex  items-center bg-[var(--black700)] mt-4 rounded-full py-3 px-6 text-sm text-white `}
        > */}
        <button
          onClick={goToMap}
          className={` flex  items-center bg-[var(--black700)] mt-4 rounded-full py-3 px-6 text-sm text-white `}
        >
          <span className="mr-3"> Get direction</span>{" "}
          <img src={send} className="mt-[2.5px]" alt="GET DIRECTION" />
        </button>
        {/* </NavLink> */}
      </div>
    </section>
  );
}
