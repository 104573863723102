import React from "react";
import { BiCurrentLocation } from "react-icons/bi";

import { Tooltip as ReactTooltip } from "react-tooltip";

import Select from "react-select";
import {
  setCachedAddress,
  setCurrentCoordinate,
} from "../../utils/getUserLocation";
import { useGoogleAddress } from "../../hooks/GoogleAddressHook";

export default function SearchBar({
  setSearchBasedOnLocation,
  setSearchedAddress,

  setSearchedLocation,
  setCoordinate,
}) {
  //custom hook
  const {
    getPlacePredictions,
    isPlacePredictionsLoading,
    placesService,
    placePredictions,
  } = useGoogleAddress();

  return (
    <section
      className={`pl-2 flex items-center mt-2 bg-[var(--inputGray)] py-3 w-full rounded-lg`}
    >
      <div
        data-tooltip-id="my-tooltip"
        data-tooltip-content="Click to search based on your location"
        onClick={() => setSearchBasedOnLocation(true)}
      >
        <BiCurrentLocation color="gray" size={25} />
        <ReactTooltip
          style={{
            fontSize: "12px",
          }}
          id="my-tooltip"
          effect="solid"
        />
      </div>

      <Select
        placeholder="Search your location"
        onChange={(selectedOption) => {
          const placeId = selectedOption.value;
          setCachedAddress(selectedOption.label);
          setSearchedAddress(selectedOption.label);
          placesService?.getDetails(
            {
              placeId: placeId,
            },
            (placeDetails) => {
              const latitude = placeDetails.geometry.location.lat();
              const longitude = placeDetails.geometry.location.lng();
              setSearchedLocation({
                lat: latitude,
                long: longitude,
              });

              // setCurrentCoordinate({
              //   lat: latitude,
              //   long: longitude,
              // });

              setCoordinate({
                lat: latitude,
                long: longitude,
              });
            }
          );
        }}
        onInputChange={(inputValue) => {
          getPlacePredictions({ input: inputValue });
        }}
        isLoading={isPlacePredictionsLoading}
        options={placePredictions.map((item) => ({
          value: item.place_id,
          label: item.description,
        }))}
        className={` w-[90%] bg-[var(--inputGray)] outline outline-0 text-sm`}
        styles={{
          control: (provided, state) => ({
            ...provided,
            border: "none",
            outline: "none",
            backgroundColor: "transparent", // Set the background color to transparent
          }),
        }}
      />
    </section>
  );
}
