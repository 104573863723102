import React from "react";
import { NavLink } from "react-router-dom";
import OnboardingCarousel from "../../components/Onboarding/OnboardingCarousel";

export default function OnBoarding() {
  return (
    <section className={`h-[100dvh] w-[90%] mx-auto relative`}>
      <section className={`absolute bottom-2 w-full`}>
        <OnboardingCarousel />

        <section className={` flex`}>
          <div className={`h-1 w-10 bg-[var(--black500)] mr-4`}></div>
          <div className={`h-1 w-10 bg-[var(--black5)] mr-4`}></div>
          <div className={`h-1 w-10 bg-[var(--black5)] mr-4`}></div>
        </section>

        <NavLink to="/register">
          <button
            className={`bg-[var(--orange600)]
             my-10 mx-auto text-sm py-4 text-white w-full rounded-full `}
          >
            Join Now
          </button>
        </NavLink>
      </section>
    </section>
  );
}
