import React from "react";
import ServerError from "./ServerError";
import NetworkError from "./NetworkError";
import { useSelector } from "react-redux";

export default function ErrorWrapper({ children }) {
  const { isNetworkError, isServerError } = useSelector(
    (state) => state.errorHandling
  );
  return (
    <>
      {isServerError && <ServerError />}
      {isNetworkError && <NetworkError />}

      {!isNetworkError && !isServerError && children}
    </>
  );
}
