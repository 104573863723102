import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getUserProfile } from "../../store/Slices/fetchUserProfile";
import { RiLockPasswordLine } from "react-icons/ri";
import { GiMoneyStack } from "react-icons/gi";
import { AiOutlineDelete } from "react-icons/ai";

//assets
import ArrowRight from "../../assets/svg/arrowright.svg";
import document from "../../assets/svg/document.svg";
import logout from "../../assets/svg/logout.svg";
import avatar from "../../assets/images/avatar.jpg";
import logo from "../../assets/svg/logo.svg";

//components
import Loader from "../../components/Loader";
import { DeleteUserAcct } from "../../store/Slices/deleteUserAcctSlice";

export default function FuelAccount() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userProfile } = useSelector((state) => state.userProfile);

  //custom function
  const handleLogout = () => {
    localStorage.clear();
    navigate({
      pathname: "/login",
    });
  };

  const deleteAccount = () => {
    let answer = window.confirm(
      "Are you sure you want to delete your account?"
    );

    if (answer) {
      // User clicked OK, take action to delete the account
      // Place your code to delete the account here

      dispatch(DeleteUserAcct()).finally((e) => {
        localStorage.clear();
        navigate("/login");
      });

      console.log("Account deletion initiated");
    } else {
      // User clicked Cancel, do nothing or provide feedback
      console.log("Account deletion cancelled");
    }
  };

  //useeffect
  useEffect(() => {
    dispatch(getUserProfile());
  }, [dispatch]);

  return (
    <>
      {userProfile?.isLoading && <Loader />}

      {!userProfile?.isLoading && (
        <>
          <section className=" bg-[#FDFAFA] p-2 py-6">
            <section className="w-[90%] mx-auto">
              <section
                className={` flex items-center justify-center py-4 flex-col mt-3 bg-[#fff]  w-full rounded-lg`}
              >
                <div className="border-[3px] mb-2 p-2 border-red-600 rounded-full">
                  <img
                    src={logo}
                    className="w-10 h-10  rounded-full "
                    alt="Avatar"
                  />
                </div>

                <p className="text-[#252525] text-sm font-bold">
                  {" "}
                  {userProfile?.data?.profile?.firstName}{" "}
                  {userProfile?.data?.profile?.lastName}{" "}
                </p>
                <p className="text-[#515151] text-sm font-normal pb-3">
                  {userProfile?.data?.profile?.email}
                </p>
              </section>
              <div className="text-[#101010] font-medium text-xs pt-6 pb-1">
                MY ACCOUNT
              </div>

              <section className="">
                {/* <div className="    bg-[#fff] py-6 w-full px-4 h-[72px] rounded-lg">
                <div className="flex items-center justify-between">
                  <div className="flex items-center gap-x-4">
                    <div>
                      <img src={MiniProfile} alt="" />
                    </div>
                    <div className="text-[#101828] font-normal text-sm">
                      Profile Data
                    </div>
                  </div>
                  <div>
                    <img src={ArrowRight} alt="" />
                  </div>
                </div>
              </div> */}

                <Link to="/suggest-station">
                  <div className="   mt-3 bg-[#fff] py-6 px-4 h-[72px] w-full rounded-lg">
                    <div className="flex items-center justify-between">
                      <div className="flex items-center gap-x-4">
                        <div>
                          <img src={document} alt="" />
                        </div>
                        <div className="text-[#101828] font-normal text-sm">
                          Suggest station
                        </div>
                      </div>
                      <div>
                        <img src={ArrowRight} alt="" />
                      </div>
                    </div>
                  </div>
                </Link>

                <Link to="/rewards-page">
                  <div className="   mt-3 bg-[#fff] py-6 px-4 h-[72px] w-full rounded-lg">
                    <div className="flex items-center justify-between">
                      <div className="flex items-center gap-x-4">
                        <div>
                          <GiMoneyStack size={20} />
                        </div>

                        <div className="text-[#101828] font-normal text-sm">
                          My Rewards
                        </div>
                      </div>

                      <div>
                        <img src={ArrowRight} alt="" />
                      </div>
                    </div>
                  </div>
                </Link>

                <Link to="/change-password">
                  <div className="   mt-3 bg-[#fff] py-6 px-4 h-[72px] w-full rounded-lg">
                    <div className="flex items-center justify-between">
                      <div className="flex items-center gap-x-4">
                        <div>
                          <RiLockPasswordLine size={20} />
                        </div>
                        <div className="text-[#101828] font-normal text-sm">
                          Change password
                        </div>
                      </div>
                      <div>
                        <img src={ArrowRight} alt="" />
                      </div>
                    </div>
                  </div>
                </Link>

                <div className="   mt-3 bg-[#fff] py-6 px-3 h-[72px] w-full rounded-lg">
                  <div
                    onClick={handleLogout}
                    className="flex items-center justify-between"
                  >
                    <div className="flex items-center gap-x-4">
                      <div>
                        <img src={logout} alt="" />
                      </div>
                      <div className="text-[#EB3540] font-normal text-sm ">
                        Logout
                      </div>
                    </div>
                  </div>
                </div>

                <div className="  mt-3 bg-[#fff] py-6 px-3 h-[72px] w-full rounded-lg">
                  <div
                    onClick={deleteAccount}
                    className="flex items-center justify-between"
                  >
                    <div className="flex items-center gap-x-4">
                      <div>
                        <AiOutlineDelete size={20} className="text-red-700" />
                      </div>
                      <div className="text-red-700  font-bold text-sm ">
                        Delete Account
                      </div>
                    </div>
                  </div>
                </div>
              </section>

              {/* <div className="text-[#101010] font-medium  text-xs pt-6 pb-1">
              PREFERENCE
            </div> */}

              {/* <section className="">
              <div className=" bg-[#fff] py-6 px-4 h-[72px] w-full rounded-lg">
                <div className="flex items-center justify-between">
                  <div className="flex items-center gap-x-4">
                    <div>
                      <img src={mail} alt="" />
                    </div>
                    <div className="text-[#101828] font-normal text-sm">
                      Email Notification
                    </div>
                  </div>
                  <div>
                    <Switch />
                  </div>
                </div>
              </div>
              <div className="   mt-3 bg-[#fff] py-6 px-4 h-[72px] w-full rounded-lg">
                <div className="flex items-center justify-between">
                  <div className="flex items-center gap-x-4">
                    <div>
                      <img src={paper} alt="" />
                    </div>
                    <div className="text-[#101828] font-normal text-sm">
                      Terms & condition
                    </div>
                  </div>
                  <div>
                    <img src={longarrow} alt="" />
                  </div>
                </div>
              </div>
              <div className="   mt-3 bg-[#fff] py-6 px-4 h-[72px] w-full rounded-lg">
                <div className="flex items-center justify-between">
                  <div className="flex items-center gap-x-4">
                    <div>
                      <img src={message} alt="" />
                    </div>
                    <div className="text-[#101828] font-normal text-sm">
                      Support
                    </div>
                  </div>
                  <div>
                    <img src={longarrow} alt="" />
                  </div>
                </div>
              </div>
            
            </section> */}
            </section>
          </section>

          <section className="mt-4">
            <div>
              <h5 className="text-xs text-center font-semibold">
                Fuelprice v1
              </h5>
            </div>
          </section>
        </>
      )}
    </>
  );
}
