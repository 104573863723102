import React from "react";
import { useState } from "react";
import { ClipLoader } from "react-spinners";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";

import axios from "axios";
//assets
import Show from "../../assets/svg/eye.svg";
import logo from "../../assets/svg/logo.svg";
// import { baseUrl } from "../../utils/baseUrl";

export default function ResetPassword() {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const code = searchParams.get("code");

  //usestates
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [inputType, setInputType] = useState("password");
  const [inputType2, setInputType2] = useState("password");
  const [isLoading, setIsLoading] = useState(false);
  const [isValid, setIsValid] = useState(true);
  const [hasUpperCase, setHasUpperCase] = useState(false);
  const [hasLowerCase, setHasLowerCase] = useState(false);
  const [hasNumber, setHasNumber] = useState(false);
  const [hasSpecial, setHasSpecial] = useState(false);
  const [isLongEnough, setIsLongEnough] = useState(false);

  //useeffects
  useEffect(() => {
    showPassword ? setInputType("text") : setInputType("password");
  }, [showPassword]);

  useEffect(() => {
    showPassword2 ? setInputType2("text") : setInputType2("password");
  }, [showPassword2]);

  //custom functions
  const checkPassword = (e) => {
    let entry = e.target.value;
    setHasUpperCase(/[A-Z]/.test(entry));
    setHasLowerCase(/[a-z]/.test(entry));
    setHasNumber(/[0-9]/.test(entry));
    setHasSpecial(/[\W_]/.test(entry));
    setIsLongEnough(entry.length > 7);
  };

  //http requests
  const resetPassword = (e) => {
    e.preventDefault();
    setIsLoading(true);
    let password = e?.target?.password?.value;
    let confirmPassword = e?.target?.confirmPassword?.value;
    let email = e?.target?.email?.value;
    if (!email) {
      toast.error("Enter your email address ");
      setIsLoading(false);
      return;
    }

    if (!password) {
      toast.error("Enter password");
      setIsLoading(false);
      return;
    }

    if (!confirmPassword) {
      toast.error("Enter Confirm Password");
      setIsLoading(false);
      return;
    }

    if (password !== confirmPassword) {
      toast.error("New and Confirmed password are different");
      setIsLoading(false);
      return;
    }

    if (!code) {
      toast.error("Url is incorrect");
      setIsLoading(false);
      return;
    }

    if (!isLongEnough) {
      toast.error("Password must be 8 digit long");
      setIsLoading(false);
      return;
    }

    if (!hasLowerCase) {
      toast.error("Password must contain a lowercase letter");
      setIsLoading(false);
      return;
    }

    if (!hasUpperCase) {
      toast.error("Password must contain an Uppercase letter");
      setIsLoading(false);
      return;
    }

    if (!hasNumber) {
      toast.error("Password must contain a number");
      setIsLoading(false);
      return;
    }

    if (!hasSpecial) {
      toast.error("Password must contain a special character");
      setIsLoading(false);
      return;
    }

    // const regex =
    //   /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;

    // if (!regex.test(password)) {
    //   toast.error(
    //     " New password should contain a Lowercase letter, Uppercase letter, At least a numuber, And a special character"
    //   );
    //   setIsValid(false);
    //   setIsLoading(false);
    //   return;
    // }

    let data = {
      email,
      password,
      confirmPassword,
      code,
    };

    axios
      .post(`${process.env.REACT_APP_BASE_URL}/ResetPassword`, data)
      .then((res) => {
        localStorage.removeItem("email");
        toast.success("Password changed successfully");
        navigate("/login");
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err?.message);
        if (err?.message) {
          toast.error(err?.message);
        }
        toast.error(err?.response?.data?.message);
      });
  };

  return (
    <section className="w-[90%] mx-auto">
      <div className="flex justify-center mt-20 mb-10">
        <img src={logo} alt="Fuelprice logo" className="w-[2.9rem]" />
      </div>

      <section>
        <h3 className="text-2xl font-bold ">Create new password</h3>
        <p className="mt-2">
          Your new password must be different from the previous used passwords
        </p>

        <form onSubmit={resetPassword}>
          <div className="mt-8">
            <label className={`block text-base font-semibold pl-1`}>
              Email
            </label>
            <div
              className={` flex items-center justify-between mt-2   w-full rounded-lg bg-[var(--inputGray)] placeholder:text-[var(--black10)] text-sm`}
            >
              <input
                required
                name="email"
                type="email"
                placeholder="Enter email address"
                className={`  py-4 pl-4 rounded-lg w-[90%] outline-none bg-[var(--inputGray)] placeholder:text-[var(--black10)] text-sm`}
              />
            </div>
          </div>

          <div className="mt-5">
            <label className={`block text-base font-semibold pl-1`}>
              Password
            </label>
            <div
              className={` flex items-center justify-between mt-2   w-full rounded-lg bg-[var(--inputGray)] placeholder:text-[var(--black10)] text-sm`}
            >
              <input
                required
                name="password"
                type={inputType}
                onChange={(e) => checkPassword(e)}
                placeholder="Enter new password"
                className={`  py-4 pl-4 rounded-lg w-[90%] outline-none bg-[var(--inputGray)] placeholder:text-[var(--black10)] text-sm`}
              />
              <img
                src={Show}
                className="mr-5"
                onClick={() => {
                  setShowPassword((showPassword) => !showPassword);
                }}
                alt="show password"
              />
            </div>
          </div>

          <div className="mt-5">
            <label className={`block text-base font-semibold pl-1`}>
              Confirm Password
            </label>
            <div
              className={` flex items-center justify-between mt-2   w-full rounded-lg bg-[var(--inputGray)] placeholder:text-[var(--black10)] text-sm`}
            >
              <input
                required
                name="confirmPassword"
                type={inputType2}
                placeholder="Confirm password"
                className={`  py-4 pl-4 rounded-lg w-[90%] outline-none bg-[var(--inputGray)] placeholder:text-[var(--black10)] text-sm`}
              />
              <img
                src={Show}
                className="mr-5"
                onClick={() => {
                  setShowPassword2((showPassword2) => !showPassword2);
                }}
                alt="show password"
              />
            </div>
          </div>

          <div
            className={`text-sm leading-loose mt-3 ml-2 ${
              !isValid ? "text-[#6B6B6B]" : "text-[var(--orange600)]"
            } `}
          >
            <p className="mb-1 text-base text-black">
              Your password must contain
            </p>

            <ul className="pl-3 list-disc">
              <li
                className={`${
                  isLongEnough ? "text-green-600" : "text-[var(--orange600)]"
                }`}
              >
                <span>Between 8 to 20 characters</span>
              </li>

              <li
                className={`${
                  hasLowerCase ? "text-green-600" : "text-[var(--orange600)]"
                }`}
              >
                <span>A Lowercase letter</span>
              </li>

              <li
                className={`${
                  hasUpperCase ? "text-green-600" : "text-[var(--orange600)]"
                }`}
              >
                <span>An Uppercase letter</span>
              </li>

              <li
                className={`${
                  hasNumber ? "text-green-600" : "text-[var(--orange600)]"
                }`}
              >
                <span>1 or more numbers</span>
              </li>

              <li
                className={`${
                  hasSpecial ? "text-green-600" : "text-[var(--orange600)]"
                }`}
              >
                <span>A special characters</span>
              </li>
            </ul>
          </div>

          <button
            className={` ${
              isLoading
                ? "disabled  bg-[var(--orange60070)]"
                : " bg-[var(--orange600)]"
            } my-10  mt-8 mx-auto text-sm py-4 text-white w-full rounded-lg `}
            disabled={isLoading ? true : false}
          >
            {" "}
            {isLoading ? <ClipLoader color="white" size={12} /> : " Submit"}
          </button>
        </form>
      </section>
    </section>
  );
}
