export const getPriceRange = (filteredStation, selectedProduct) => {
  let price = [];
  filteredStation.forEach((el) => {
    return el.products.map((product) => {
      if (product.name.toLowerCase() === selectedProduct.value.toLowerCase() ) {
        price.push(product.price);
      }
    });
  });

 return price.filter((num) => num > 100 && num !== -1);
};
