import React from "react";
import styles from "./style.module.css";
import logo from "../../assets/svg/logo.svg";
import { getDeviceHeight } from "../../utils/getDeviceHeight";

export default function Loader() {
  let deviceHeight = getDeviceHeight
  return (
 
      <div style={{height:deviceHeight}} className={styles.pulseWrapper}>
        <div className={styles.pulse}>
          <img src={logo} alt="Page loading" />
        </div>
      </div>

  );
}
