import React from "react";
import moment from "moment/moment";
import { useLocation, useNavigate } from "react-router-dom";

import { breakNumber } from "../../utils/breakNumber";
import { convertDistance } from "../../utils/convertDistance";
import { truncate } from "../../utils/truncate";

//assets
import profileIcon from "../../assets/svg/prof.svg";
import verified from "../../assets/svg/verify.svg";
import { CustomImg } from "../../utils/customImg";

export default function StationCard({ station, type }) {
  const navigate = useNavigate();
  let { pathname } = useLocation();

  //variables
  let finalPrice, updatedBy, updateTime;
  let productPrice = station?.products.filter(
    (el) => el.name?.toLowerCase() === type?.toLowerCase()
  );

  if (productPrice) {
    finalPrice = breakNumber(productPrice[0]?.price);

    updatedBy =
      productPrice[0]?.priceUpdateBy === "" || !productPrice[0]?.priceUpdateBy
        ? "Anonymous"
        : productPrice[0]?.priceUpdateBy;

    updateTime = productPrice[0]?.lastPriceUpdateDate
      ? moment(productPrice[0]?.lastPriceUpdateDate).fromNow()
      : "Unknown";
  }

  let checkIfNegative = productPrice?.[0]?.price;

  //functions
  const navigateToReportPage = () => {
    navigate({
      to: `/report-price?q=${station?.id}`,
      state: { stationDetail: station },
    });
  };

  return (
    <section
      className={`bg-[var(--inputGray)] flex justify-center justify-between items-center py-4 px-4 rounded-lg mt-4`}
    >
      <div className="flex items-center mr-2">
        <div className={`mr-2 w-[2.4rem]`}>
          {!station?.companyLogoUrl ? (
            CustomImg(station?.companyName)
          ) : (
            <img
              src={station?.companyLogoUrl}
              alt="company logo"
              className={` rounded-full object-contain`}
            />
          )}
        </div>

        <div className="text-left">
          <h2
            className={`font-[Moderat] font-[900] text-[var(--black500)]  text-xs  `}
          >
            {truncate(station?.companyName, 19)}
          </h2>
          <h6 className={`text-xs pt-1 text-[var(--black400)]`}>
            {truncate(station?.street, 19)}
          </h6>
          <p className={`text-xs pt-1 text-[var(--orange700)]`}>
            {!pathname.includes("/state/") &&
              convertDistance(station?.distanceToTarger)}
          </p>
        </div>
      </div>

      {(checkIfNegative === -1 || checkIfNegative === 0) && (
        // <NavLink
        //   state={{ stationDetail: station }}
        //   to={`/report-price?q=${station?.id}`}
        // >
        <div onClick={navigateToReportPage}>
          <button
            className={`px-2 py-1 text-xs flex items-center bg-[var(--orange600)] rounded text-white `}
          >
            Add Price
          </button>
        </div>
        // </NavLink>
      )}

      {pathname !== "/stations" &&
        checkIfNegative !== -1 &&
        checkIfNegative !== 0 && (
          <div>
            {finalPrice && (
              <h1
                className={`font-[Moderat] text-[var(--black600)] text-sm font-bold`}
              >
                NGN
                {finalPrice[0]}.<sup className="text-xs">{finalPrice[1]}</sup>
              </h1>
            )}

            <div className={`flex items-center `}>
              <img
                src={profileIcon}
                alt="my account link"
                className="mr-2 w-[0.625rem] mt-1"
              />{" "}
              <p className="text-xs text-[var(--black10)] font-semibold">
                {updatedBy}
              </p>
            </div>

            <div>
              <p className="text-xs text-[var(--black10)] mt-1 font-semibold">
                {updateTime}
              </p>
            </div>
          </div>
        )}

      {pathname === "/stations" && checkIfNegative !== -1 && (
        <div>
          <img src={verified} alt="my account link" className="mt-1 mr-2" />{" "}
        </div>
      )}
    </section>
  );
}
