import React from "react";
import NavBar from "../NavBar";

export default function Layout({ children }) {
  return (
    // max-h-[90dvh]
    <section className="relative min-h-[100dvh]">
      <div className=" pb-10 mb-20 overflow-y-auto">{children}</div>
      <nav className="fixed bottom-0 w-full bg-white border-t-2 border-[var(--grey100)]" >
        <NavBar />
      </nav>
    </section>
  );
}
