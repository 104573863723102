import React, { useEffect, useState } from "react";
import locationIcon from "../../assets/svg/locationIcon.svg";
import filterIcon from "../../assets/svg/filter.svg";
import PriceRange from "../../components/Dashboard/PriceRange";
import StationCard from "../../components/Dashboard/StationCard";
import banner from "../../assets/svg/banner.svg";

import { NavLink, useLocation, useParams } from "react-router-dom";

import axios from "../../lib/axiosInterceptor";
import Loader from "../../components/Loader";
import Select from "react-select";
import { toast } from "react-toastify";

import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import { Input } from "antd";
import { InsertAD } from "../../utils/insertAD";
import { getStationsByProducts } from "../../utils/getStationsByProduct";

export default function StateStation() {
  const [stations, setStations] = useState();
  const [priceRange, setPriceRange] = useState();
  const [numberOfStation, setNumberOfStation] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const [selectedProduct, setSelectedProduct] = useState({
    value: "PMS",
    label: "PMS",
  });

  const [isLocationPromptWorking, setIsLocationPromptWorking] = useState(false);

  const [distance, setDistance] = useState(5000);
  const [address, setAddress] = useState("");
  const [lga, setLGA] = useState("");
  const [pageSize, setPageSize] = useState(5000);

  const [longLat, setLongLat] = useState();
  const [searchedLocation, setSearchedLocation] = useState();
  const location = useLocation();

  const { state } = useParams();

  const getStations = () => {
    // if (!longLat) {
    //   return;
    // }

    let url;
    if (longLat) {
      url = `/station?latitude=${longLat?.lat}&longitude=${longLat?.long}&distance=${distance}&pageSize=${pageSize}`;
    } else {
      url = `/Station/ByState/${state}`;
    }

    setIsLoading(true);
    axios
      .get(url)
      .then((res) => {
        let filteredStation;

        if (!res.data.dataObject) return;
        filteredStation = getStationsByProducts(
          res.data.dataObject,
          selectedProduct
        );

        // SAVE STATION LIST TO MAKE IT EASIER TO GET TO THE DETAILS PAGE
        // localStorage.setItem(
        //   "nearbyStations",
        //   JSON.stringify(res.data.dataObject)
        // );
        ///////

        setStations(filteredStation);

        //GET HIGHEST AND LOWEST PRICE PER PRODUCT
        let price = [];
        filteredStation.forEach((el) => {
          return el.products.map((product) => {
            if (
              product.name.toLowerCase() === selectedProduct.value.toLowerCase()
            ) {
              price.push(product.price);
            }
          });
        });

        setPriceRange({
          highest: Math.max(...price),
          lowest: Math.min(...price),
        });
        ///

        setIsLoading(false);

        setNumberOfStation(filteredStation.length);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //GET USER'S LOCATION
  function success(position) {
    const latitude = position.coords.latitude;
    const longitude = position.coords.longitude;
    setLongLat({
      lat: latitude,
      long: longitude,
    });

    // getAddressFromCoordinates(latitude, longitude);
  }

  function error() {
    // toast.error("Unable to retrieve your location");
    console.log("Unable to retrieve your location");
  }

  //   useEffect(() => {
  //     // console.log(navigator)

  //     setIsLocationPromptWorking(navigator.permissions);
  //     console.log(navigator.permissions);
  //     if (navigator.geolocation) {
  //       navigator.permissions
  //         .query({ name: "geolocation" })
  //         .then(function (result) {
  //           if (result.state === "granted") {
  //             //If granted then you can directly call your function here
  //             navigator.geolocation.getCurrentPosition(success, error);
  //           } else if (result.state === "prompt") {
  //             //If prompt then the user will be asked to give permission
  //             setIsLocationPromptWorking(false);
  //             navigator.geolocation.getCurrentPosition(success, error);
  //           } else if (result.state === "denied") {
  //             setIsLocationPromptWorking(false);
  //             //If denied then you have to show instructions to enable location
  //             // toast.info(
  //             //   "Kindly enable your location so we can show the stations closer to you"
  //             // );
  //           }
  //         });
  //     } else {
  //       console.log("Geolocation is not supported by this browser.");
  //     }
  //   }, []);

  useEffect(() => {
    setLongLat();
  }, []);
  useEffect(() => {
    getStations();
  }, [state]);

  const options = [
    { value: "PMS", label: "PMS" },
    { value: "AGO", label: "AGO" },
    { value: "DPK", label: "DPK" },
    { value: "LPG", label: "LPG" },
  ];

  //SEARCH LOCATION

  const {
    placesService,
    placePredictions,
    getPlacePredictions,
    isPlacePredictionsLoading,
  } = usePlacesService({
    apiKey: process.env.REACT_APP_GOOGLEMAP_API,
  });

  useEffect(() => {
    // fetch place details for the first element in placePredictions array
    if (placePredictions.length)
      placesService?.getDetails(
        {
          placeId: placePredictions[0].place_id,
        },
        (placeDetails) => {
          return placeDetails;
        }
      );
  }, [placePredictions]);

  useEffect(() => {
    if (longLat) {
      getStations();
    }
  }, [longLat]);

  ////

  useEffect(() => {
    if (!stations) return;
    let filteredStation = getStationsByProducts(stations, selectedProduct);
    let price = [];
    filteredStation.forEach((el) => {
      return el.products.map((product) => {
        if (
          product.name.toLowerCase() === selectedProduct.value.toLowerCase()
        ) {
          price.push(product.price);
        }
      });
    });
    setPriceRange({
      highest: Math.max(...price),
      lowest: Math.min(...price),
    });
    ///

    setIsLoading(false);

    setNumberOfStation(filteredStation.length);
    setStations(filteredStation);
  }, [selectedProduct]);

  console.log(stations);

  return (
    <>
      {isLoading && <Loader />}

      {!isLoading && (
        <section className={`w-[90%] mx-auto `}>
          <section
            className={`pl-2 flex items-center mt-3 bg-[var(--inputGray)] py-3 w-full rounded-lg`}
          >
            <img src={locationIcon} alt="Location icon" />

            <Select
              placeholder="Search your location"
              onChange={(selectedOption) => {
                const placeId = selectedOption.value;
                setSearchedLocation(selectedOption.label);

                placesService?.getDetails(
                  {
                    placeId: placeId,
                  },
                  (placeDetails) => {
                    const latitude = placeDetails.geometry.location.lat();
                    const longitude = placeDetails.geometry.location.lng();

                    setLongLat({ lat: latitude, long: longitude });
                  }
                );
              }}
              onInputChange={(inputValue) => {
                getPlacePredictions({ input: inputValue });
              }}
              isLoading={isPlacePredictionsLoading}
              options={placePredictions.map((item) => ({
                value: item.place_id,
                label: item.description,
              }))}
              className={`ml-1 w-[90%] bg-[var(--inputGray)] outline outline-0`}
              styles={{
                control: (provided, state) => ({
                  ...provided,
                  border: "none",
                  outline: "none",
                  backgroundColor: "transparent", // Set the background color to transparent
                }),
              }}
            />
          </section>

          <PriceRange
            priceRange={priceRange}
            numberOfStation={numberOfStation}
          />

          <section className={`mt-4`}>
            <div className={`flex items-center justify-center justify-between`}>
              <Select
                defaultValue={selectedProduct}
                onChange={setSelectedProduct}
                options={options}
                className={`border-0 text-[var(--black500)] font-semibold text-base`}
              />

              <div className={`mr-2`}>
                <img src={filterIcon} alt="Filter icon" />
              </div>
            </div>

            <div className="mt-4 mb-8">
              <p className="text-sm ml-2 font-semibold mt-4">
                {" "}
                {longLat
                  ? `Stations around ${searchedLocation}`
                  : `Stations around ${state}`}
              </p>
              {stations && (
                <div className="flex flex-col">
                  {/* {stations && renderObjectsWithImages()} */}
                  {stations && InsertAD(stations, selectedProduct)}
                </div>
              )}

              {stations && stations?.length < 1 && (
                <div className="flex flex-col">
                  <p className="text-center text-sm font-semibold mt-20 w-[90%] mx-auto">
                    We can't find a station nearby
                  </p>
                </div>
              )}

              {!isLocationPromptWorking && !stations && (
                <p className="text-center text-sm font-semibold mt-20 w-[90%] mx-auto">
                  Search for your area or enable your location to show you
                  stations nearby.
                </p>
              )}
            </div>
          </section>
        </section>
      )}
    </>
  );
}
