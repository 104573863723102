import React from "react";
import companylogo from "../../assets/svg/companylogo.svg";
import verified from "../../assets/svg/verify.svg";
import { convertDistance } from "../../utils/convertDistance";
import { CustomImg } from "../Details/Banner";

export default function ReportBanner({
  stationDetail: { name, street, companyLogoUrl, distanceToTarger, details },
}) {
  return (
    <section className={`bg-[var(--inputGray)] rounded-lg mt-5 py-4 `}>
      <div className={`w-full flex items-center justify-center relative`}>
        <img
          src={verified}
          alt="Popular stations"
          className="absolute bottom-[-6px] w-[1.25rem] z-5"
        />
        {/* <img
          src={companyLogoUrl}
          alt="company logo"
          className="border-4 border-[var(--grey300)] rounded-full w-[4rem] h-[4rem]"
        /> */}
        {companyLogoUrl && (
          <img
            src={verified}
            alt="Popular stations"
            className="absolute bottom-[-6px] w-[1.25rem] z-5"
          />
        )}

        {companyLogoUrl ? (
          <img
            src={companyLogoUrl}
            alt="company logo"
            className="object-contain border-4 border-[var(--grey300)] rounded-full w-[4rem] h-[4rem]"
          />
        ) : (
          <div className="bg-[var(--orange600)] text-center text-white py-2 flex justify-center items-center text-xs rounded-full w-16 h-16">
            {/* <CustomImg /> */}
            {name[0]} {name[1].toUpperCase()}
          </div>
        )}
      </div>

      <div className="w-[90%] mx-auto mt-2">
        <div className="flex items-center justify-between">
          <h2 className="font-semibold text-sm">{name}</h2>
          <p className="text-[var(--orange700)] text-xs font-semibold">
            {convertDistance(distanceToTarger)}
          </p>
        </div>

        <p className="text-xs font-semibold text-left mt-1 text-[var(--black400)]">
          {street}
        </p>
      </div>
    </section>
  );
}
