import React from "react";

import logo from "../src/assets/svg/logo.svg";

import "./App.css";

import { BrowserRouter, Route, Routes } from "react-router-dom";

import OTP from "./pages/Authentication/OTP";
import Register from "./pages/Authentication/Register";
import Details from "./pages/Details";
import ReportPrice from "./pages/ReportPrice";
import SuggestStation from "./pages/SuggestStation";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Layout from "./components/Layout";
import Login from "./pages/Authentication/Login";
import OnBoarding from "./pages/OnBording";
import StationList from "./pages/StationList";
import AuthRoutes from "./routeGuard/AuthRoute";
import ProtectedRoutes from "./routeGuard/ProtectedRoute";

import { Helmet } from "react-helmet";
import { OGP } from "react-ogp";
import DemoPage from "./pages/DemoPage";
import FuelAccount from "./pages/Profile/FuelAccount";
import LGAStation from "./pages/LGAStation";
import Rewards from "./pages/Rewards/Rewards";
import StateStation from "./pages/StateStation";
import ResetPassword from "./pages/Authentication/ResetPassword";
import ForgotPassword from "./pages/Authentication/FogotPassword";
import PasswordSent from "./pages/Authentication/FogotPassword/PasswordSent";
import PageNotFound from "./pages/404page";
import ChangePassword from "./pages/Authentication/ChangePassword";
import Dashboardd from "./pages/Dashboard";

function App() {
  return (
    <div>
      <>
        <Helmet>
          <OGP
            url="https://www.fuelprice.ng/"
            title="Fuelprice"
            description="Welcome to Fuelprice.ng, your ultimate resource for real-time fuel price information across all stations in Nigeria. Discover the latest fuel prices and make informed decisions before you hit the road"
            siteName="fuelprice.ng"
            image={logo}
          />

          <title>FuelPrice</title>
          <meta
            name="description"
            content="Welcome to Fuelprice.ng, your ultimate resource for real-time fuel price information across all stations in Nigeria. Discover the latest fuel prices and make informed decisions before you hit the road"
          />
          <meta property="og:url" content="https://www.fuelprice.ng/" />
          <meta property="og:title" content="Fuelprice" />
          <meta
            property="og:description"
            content="Welcome to Fuelprice.ng, your ultimate resource for real-time fuel price information across all stations in Nigeria. Discover the latest fuel prices and make informed decisions before you hit the road"
          />
          <meta property="og:site_name" content="fuelprice.ng" />
          <meta property="og:image" content={logo} />
        </Helmet>
        <ToastContainer position="top-center" autoClose={3000} />
        <BrowserRouter>
          <Routes>
            <Route>
              <Route
                element={
                  <AuthRoutes>
                    <Register />
                  </AuthRoutes>
                }
                path="/register"
              />
            </Route>
            <Route>
              <Route
                element={
                  <AuthRoutes>
                    <DemoPage />
                  </AuthRoutes>
                }
                path="/"
              />
            </Route>

            <Route>
              <Route
                element={
                  <AuthRoutes>
                    <Login />
                  </AuthRoutes>
                }
                path="/login"
              />
            </Route>

            <Route>
              <Route
                element={
                  <AuthRoutes>
                    <DemoPage />
                  </AuthRoutes>
                }
                path="/"
              />
            </Route>

            <Route>
              <Route
                element={
                  <AuthRoutes>
                    <ForgotPassword />
                  </AuthRoutes>
                }
                path="/forgot-password"
              />
            </Route>

            <Route>
              <Route
                element={
                  <AuthRoutes>
                    <PasswordSent />
                  </AuthRoutes>
                }
                path="/password-sent"
              />
            </Route>

            <Route>
              <Route
                element={
                  <AuthRoutes>
                    <ResetPassword />
                  </AuthRoutes>
                }
                path="/Account/ResetPassword"
              />
            </Route>

            <Route>
              <Route element={<OnBoarding />} path="/on-boarding" />
            </Route>

            {/* <Route
            element={
              <AuthRoutes>
                <Home />
              </AuthRoutes>
            }
            path="/"
          /> */}

            <Route>
              <Route
                element={
                  <AuthRoutes>
                    <OTP />
                  </AuthRoutes>
                }
                path="/otp"
              />
            </Route>

            <Route element={<ProtectedRoutes />}>
              <Route
                element={
                  <Layout>
                    <ChangePassword />
                  </Layout>
                }
                path="/change-password"
              />
            </Route>

            <Route element={<ProtectedRoutes />}>
              <Route
                element={
                  <Layout>
                    <Dashboardd/>
                    {/* <Dashboard /> */}
                  </Layout>
                }
                path="/dashboard"
              />
            </Route>

            <Route element={<ProtectedRoutes />}>
              <Route
                element={
                  <Layout>
                    <StationList />
                  </Layout>
                }
                path="/stations"
              />
            </Route>

            {/* <Route element={<ProtectedRoutes />}>
            <Route
              element={
                <Layout>
                  <StateStation />
                </Layout>
              }
              path="/state/:state"
            />
          </Route> */}

            <Route
              element={
                <Layout>
                  <StateStation />
                </Layout>
              }
              path="/state/:state"
            />

            <Route
              element={
                <Layout>
                  <LGAStation />
                </Layout>
              }
              path="/lga/:LGA"
            />

            <Route element={<ProtectedRoutes />}>
              <Route
                element={
                  <Layout>
                    <Details />
                  </Layout>
                }
                path="/details/:stationId"
              />
            </Route>

            <Route element={<ProtectedRoutes />}>
              <Route
                element={
                  <Layout>
                    <SuggestStation />
                  </Layout>
                }
                path="/suggest-station"
              />
            </Route>

            <Route element={<ProtectedRoutes />}>
              <Route
                element={
                  <Layout>
                    <ReportPrice />
                  </Layout>
                }
                path="/report-price"
              />
            </Route>
            <Route element={<ProtectedRoutes />}>
              <Route
                element={
                  <Layout>
                    <FuelAccount />
                  </Layout>
                }
                path="/profile"
              />
            </Route>
            <Route element={<ProtectedRoutes />}>
              <Route
                element={
                  <Layout>
                    <Rewards />
                  </Layout>
                }
                path="/rewards-page"
              />
            </Route>

            <Route path="*" element={<PageNotFound />} />
          </Routes>
        </BrowserRouter>
      </>
    </div>
  );
}

export default App;
