import React from "react";
import companylogo from "../../assets/svg/companylogo.svg";
import profileIcon from "../../assets/svg/prof.svg";
import verified from "../../assets/svg/verify.svg";
import { breakNumber } from "../../utils/breakNumber";
import { Link, NavLink, useLocation } from "react-router-dom";
import { convertDistance } from "../../utils/convertDistance";
import moment from "moment/moment";

export default function SuggestionCard({ suggestion }) {
  // let stringPrice = String();

  let { pathname } = useLocation();

  return (
    <section
      className={`bg-[var(--inputGray)] flex justify-center justify-between items-center py-4 px-4 rounded-lg mt-4`}
    >
      <div className="flex items-center mr-2">
        <div className={`mr-2`}>
          {/* <img
            src={suggestion?.companyLogoUrl}
            alt="company logo"
            className={`w-[2.75rem] h-[2.75rem] rounded-full object-contain`}
          /> */}
        </div>

        <div className="text-left">
          <h2 className={`font-bold text-[var(--black500)]  text-sm`}>
            {suggestion?.name}
          </h2>
          <h6 className={`text-xs pt-1 text-[var(--black400)]`}>
            {suggestion?.street}
          </h6>
          {/* <p className={`text-xs pt-1 text-[var(--orange700)]`}>
            {!pathname.includes("/state/") &&
              convertDistance(suggestion?.distanceToTarger)}
          </p> */}
        </div>
      </div>

      {/* <div>
        <Link
          state={{ suggestionDetail: suggestion }}
          to={`/report-price?q=${suggestion?.id}`}
        >
          <button
            className={`px-2 py-2 text-xs flex items-center bg-[var(--orange600)] rounded-lg text-white `}
          >
            Add Price
          </button>
        </Link>
      </div> */}
    </section>
  );
}
