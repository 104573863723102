import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { _getCloseStations } from "../apiList/apiList";

const initialState = {
  isCloseStationsLoading: true,
  closeStations: null,
  totalRecord: null,
  error: null,
};

export const getCloseStations = createAsyncThunk(
  "fetchCloseStations/getCloseStations",
  async (params) => {
    const response = await _getCloseStations(params);
    return response?.data?.dataObject;
  }
);

export const fetchCloseStationsSlice = createSlice({
  name: "fetchCloseStations",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getCloseStations.pending, (state) => {
        state.isCloseStationsLoading = true;
      })
      .addCase(getCloseStations.fulfilled, (state, { payload }) => {
        state.isCloseStationsLoading = false;
        state.closeStations = payload?.dataObject;
        state.totalRecord = payload?.pagination?.totalRecord;

        localStorage.setItem("totalRecord", payload?.pagination?.totalRecord);
      })
      .addCase(getCloseStations.rejected, (state, { payload }) => {
        state.isCloseStationsLoading = false;
        state.error = true;
      });
  },
});

export default fetchCloseStationsSlice.reducer;
