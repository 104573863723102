import React, { useEffect, useRef } from "react";
import axios from "../../lib/axiosInterceptor";
import { toast } from "react-toastify";

import { useState } from "react";
import { ClipLoader } from "react-spinners";
import Select from "react-select";
import { useNavigate } from "react-router-dom";

export default function SuggestStation() {
  const [selectedImg, setSelectedImg] = useState();
  const [companyImg, setcompanyImg] = useState();

  const [previewImg, setPreviewImg] = useState();
  const [userLocation, setUserLocation] = useState();
  const [longLat, setLongLat] = useState();
  const [isLocationPromptWorking, setIsLocationPromptWorking] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const [states, setStates] = useState();
  const [isStateLoading, setIsStateLoading] = useState(false);
  const [isLGALoading, setIsLGALoading] = useState(false);

  const [selectedState, setSelectedState] = useState();
  const [selectedLGA, setSelectedLGA] = useState();
  const [allLGA, setAllLGA] = useState();

  const stationName = useRef();
  const stationStreet = useRef();
  const stationCity = useRef();
  const stationState = useRef();
  const long = useRef();
  const lat = useRef();

  const navigate = useNavigate();

  //FETCH STATE
  const getStates = () => {
    setIsStateLoading(true);
    axios
      .get(`utility/states`)
      .then((res) => {
        let sortedStates = res.data.dataObject?.sort((a, b) => {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        });
        let newList = sortedStates?.map((state) => {
          return {
            label: state.name,
            value: state.id,
          };
        });

        setStates(newList);
        setIsStateLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //FETCH LGA
  const getLGA = (status) => {
    setIsLGALoading(true);
    axios
      .get(`/Utility/LgaByState/${selectedState?.value}`)
      .then((res) => {
        let sortedStates = res.data.dataObject?.sort((a, b) => {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        });
        let newList = sortedStates?.map((lga) => {
          return {
            label: lga.name,
            value: lga.id,
          };
        });

        setAllLGA(newList);
        setIsLGALoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //GET USER'S LOCATION
  function success(position) {
    const latitude = position.coords.latitude;
    const longitude = position.coords.longitude;
    setUserLocation({
      latitude: latitude,
      longitude: longitude,
    });

    setLongLat({
      latitude: latitude,
      longitude: longitude,
    });

    localStorage.setItem(
      "userLocation",
      JSON.stringify({ latitude: latitude, longitude: longitude })
    );

    // getAddressFromCoordinates(latitude, longitude);
  }

  function error() {
    // toast.error("Unable to retrieve your location");
    console.log("Unable to retrieve your location");
  }

  useEffect(() => {
    // console.log(navigator)

    setIsLocationPromptWorking(navigator.permissions);

    if (navigator.geolocation && navigator.permissions) {
      navigator.permissions
        .query({ name: "geolocation" })
        .then(function (result) {
          if (result.state === "granted") {
            //If granted then you can directly call your function here
            navigator.geolocation.getCurrentPosition(success, error);
          } else if (result.state === "prompt") {
            //If prompt then the user will be asked to give permission
            setIsLocationPromptWorking(false);
            navigator.geolocation.getCurrentPosition(success, error);
          } else if (result.state === "denied") {
            setIsLocationPromptWorking(false);
            //If denied then you have to show instructions to enable location
            // toast.info(
            //   "Kindly enable your location so we can show the stations closer to you"
            // );
          }
        });
    } else {
      console.log("Geolocation is not supported by this browser.");
    }
  }, []);

  useEffect(() => {
    if (selectedImg) {
      // console.log(selectedImg);
      setcompanyImg(selectedImg);
      setPreviewImg(URL.createObjectURL(selectedImg));
      // const reader = new FileReader();

      // reader.onloadend = () => {
      //   const binaryString = reader.result;
      //   setcompanyImg(binaryString);
      // };

      // reader.readAsBinaryString(selectedImg);
    }
  }, [selectedImg]);

  const suggestStation = () => {
    setIsLoading(true);
    let name = stationName.current.value;
    let street = stationStreet.current.value;
    // let city = stationCity.current.value;
    // let state = stationState.current.value;

    if (!name || name === " ") {
      toast.error("Enter the station's name");
      setIsLoading(false);
      return;
    }

    if (!street || street === " ") {
      toast.error("Enter the station's street");
      setIsLoading(false);
      return;
    }

    if (!selectedLGA) {
      toast.error("Enter the station's name");
      setIsLoading(false);
      return;
    }

    if (!selectedState) {
      toast.error("Enter the station's name");
      setIsLoading(false);
      return;
    }

    if (!userLocation) {
      toast.error(
        "Allow the website access to your location so we can pinpoint it."
      );
      setIsLoading(false);
      return;
    }

    if (!companyImg) {
      toast.error("Upload the station's image");
      setIsLoading(false);
      return;
    }

    const formData = new FormData();
    formData.append("file", companyImg);

    axios
      .post(
        `/Station/SuggestNew?name=${name}&street=${street}&city=${selectedLGA?.label}&state=${selectedState?.label}&longitude=${userLocation?.longitude}&latitude=${userLocation?.latitude}`,
        formData
      )
      .then((res) => {
        toast.success(
          "Thank you for your suggestion.A review will be conducted."
        );

        setIsLoading(false);

        navigate({
          pathname: "/stations",
        });
      })
      .catch((err) => {
        console.log(err);
        toast.success("An error occured while adding station");
        setIsLoading(false);
      });
  };

  useEffect(() => {
    selectedState && getLGA();
  }, [selectedState]);

  useEffect(() => {
    getStates();
  }, []);

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      color: "#6B6B6B",
      marginTop: "10px",
      padding: "3px",
      background: "#EEEEEE",
      border: "1px solid #6B6B6B",
      boxShadow: "0 1px 2px rgba(0, 0, 0, 0.1)",
      borderRadius: "4px",
      outline: state.isFocused ? "1px solid #6B6B6B" : "none",
      transition: "border-color 0.2s",
      width: "100%",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#A0AEC0",
    }),
    // Add more style overrides for other elements as needed
  };

  return (
    <section className="mx-auto w-[90%] ">
      <section>
        <div className={`mt-8`}>
          <h1 className={`text-[1.5rem] font-bold`}>Suggest a station</h1>
          <p className={`text-[var(--black400)] text-sm mt-2`}>
            Help us improve our coverage! Suggest a station near you by
            providing the details below.
          </p>
        </div>
      </section>

      <section className={`mt-8`}>
        <form>
          <div>
            <label className={`block text-sm font-semibold pl-1`}>
              Station name
            </label>
            <input
              ref={stationName}
              placeholder="Name of the station"
              className={`mt-2 py-3 pl-4 w-full rounded-lg bg-[var(--inputGray)] placeholder:text-[var(--black10)] text-xs`}
            />
          </div>

          <div className="mt-3">
            <label className={`block text-sm font-semibold pl-1`}>State</label>

            <Select
              onChange={(e) => setSelectedState(e)}
              styles={customStyles}
              options={states}
              placeholder="Select a state"
            />

            {/* <input
              ref={stationState}
              placeholder="Enter station state"
              className={`mt-2 py-3 pl-4 w-full rounded-lg bg-[var(--inputGray)] placeholder:text-[var(--black10)] text-xs`}
            /> */}
          </div>
          <div className="mt-3">
            <label className={`block text-sm font-semibold pl-1`}>City</label>
            <Select
              onChange={(e) => setSelectedLGA(e)}
              styles={customStyles}
              options={allLGA}
              isLoading={isLGALoading}
              placeholder="Select local government area"
            />

            {/* <input
              ref={stationCity}
              placeholder="Enter station city"
              className={`mt-2 py-3 pl-4 w-full rounded-lg bg-[var(--inputGray)] placeholder:text-[var(--black10)] text-xs`}
            /> */}
          </div>

          <div className="mt-4">
            <label className={`block text-sm font-semibold pl-1`}>
              Station address
            </label>
            <input
              ref={stationStreet}
              placeholder="Where is the station"
              className={`mt-2 py-3 pl-4 w-full rounded-lg bg-[var(--inputGray)] placeholder:text-[var(--black10)] text-xs`}
            />
          </div>

          <div className="mt-4">
            <label
              className={` text-[#AFAFAF] block text-sm font-semibold pl-1`}
            >
              Long
            </label>
            <input
              disabled
              value={longLat?.longitude ? longLat?.longitude : ""}
              placeholder="Longitude will be displayed here"
              className={` text-[#AFAFAF] mt-2 py-3 pl-4 w-full rounded-lg bg-[#F6F6F6] placeholder:text-[#AFAFAF] text-xs`}
            />
          </div>

          <div className="mt-3">
            <label
              className={` text-[#AFAFAF] block text-sm font-semibold pl-1`}
            >
              Lat
            </label>
            <input
              disabled
              value={longLat?.latitude ? longLat?.latitude : ""}
              placeholder="Latitude will be displayed here"
              className={` text-[#AFAFAF] mt-2  py-3 pl-4 w-full rounded-lg bg-[var(#F6F6F6] placeholder:text-[#AFAFAF] text-xs`}
            />
          </div>

          <div className="mt-3">
            <label className={`block text-sm font-semibold pl-1`}>
              Upload station image
            </label>

            <div
              className={` mt-3 border-dashed border-2 border-[var(--inputGray)] text-xs   rounded-lg`}
            >
              {!companyImg && (
                <label
                  htmlFor="uploadImg"
                  className={`flex flex-col justify-center py-4 px-3 w-full`}
                >
                  <p className={`text-center`}>Drop files here to upload…</p>
                  <p
                    className={`text-center py-2 rounded-lg mt-2 bg-[var(--inputGray)] text-black font-semibold w-[5.625rem] mx-auto`}
                  >
                    Browse files
                  </p>
                </label>
              )}

              {companyImg && (
                <div className="flex justify-center w-full">
                  <img src={previewImg} className="" alt="companyImg" />
                </div>
              )}
            </div>

            <input
              onChange={(e) => setSelectedImg(e.target.files[0])}
              type="file"
              accept="image/*"
              capture="environment"
              id="uploadImg"
              style={{ display: "none" }}
            />
          </div>
        </form>

        <button
          disabled={isLoading ? true : false}
          className={` ${
            isLoading ? "cursor-not-allowed" : ""
          } my-10 mx-auto text-xs  py-4 text-white w-full rounded-lg  bg-[var(--orange600)]`}
          onClick={suggestStation}
        >
          {" "}
          {isLoading ? (
            <ClipLoader size={12} color="white" />
          ) : (
            " Suggest Station"
          )}
        </button>
      </section>
    </section>
  );
}
