export const breakNumber = (num) => {

  let stringPrice = String(num);
  let finalPrice = [];
  if (!num) {
    finalPrice[0] = "0";
    finalPrice[1] = "00";
    return finalPrice;
    
  }
  if (num) {
    if (stringPrice === "0" || stringPrice === "-1") {
      finalPrice[0] = "0";
      finalPrice[1] = "00";
      return finalPrice;
    }

    if (stringPrice.includes(".")) {
      return stringPrice?.split(".");
    } else {
      finalPrice[0] = Number(stringPrice).toLocaleString();
      finalPrice[1] = "00";
      return finalPrice;
    }
  }
};
