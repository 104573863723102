import axios from "axios";
import { getToken } from "../utils/getToken";
import { toast } from "react-toastify";
// import { store } from "../store";
// import {
//   setNetworkError,
//   setServerError,
// } from "../store/Slices/ErrorHandlingSlice";
// import { handleOfflineError } from "../utils/handleOfflineError";
// import { baseUrl } from "../utils/baseUrl";

const instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    "Access-Control-Allow-Origin": "*",
  },
});

instance.interceptors.request.use(
  (config) => {
    const {
      setServerError,
      setNetworkError,
    } = require("../store/Slices/ErrorHandlingSlice");
    const store = require("../store").store;

    store.dispatch(setServerError(false));
    store.dispatch(setNetworkError(false));
    let token = getToken();
    config.headers["Authorization"] = `Bearer ${token?.token}`;

    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const {
      setServerError,
      setNetworkError,
    } = require("../store/Slices/ErrorHandlingSlice");
    const store = require("../store").store;

    let customMessage = error?.response?.data?.message;
    let errorStatus = error?.response?.status;
    let errorMessage = error?.message;

    if (errorStatus >= 400) {
      if (customMessage) {
        toast.error(customMessage);
      } else {
        toast.error(errorMessage);
      }
    }

    if (errorStatus === 405) {
      store.dispatch(setServerError(true));
      toast.error(errorMessage);
    }

    if (errorStatus === 401 || errorStatus === 403) {
      toast.error("Session Expired");
      localStorage.clear();
      window.location.href = "/";
      return Promise.reject(error);
    }

    if (errorMessage === "Network Error") {
      console.log(errorMessage);
      store.dispatch(setNetworkError(true));
    }

    if (errorStatus >= 500) {
      toast.error(errorMessage);
      store.dispatch(setServerError(true));
    }

    //REFRESH TOKEN

    // if (error.response.status === 401 && !originalRequest._retry) {
    //       originalRequest._retry = true
    //       const refreshToken = localStorageService.getRefreshToken()
    //       return axios
    //         .post('/auth/token', {
    //           refresh_token: refreshToken
    //         })
    //         .then(res => {
    //           if (res.status === 201) {
    //             localStorageService.setToken(res.data)
    //             axios.defaults.headers.common['Authorization'] =
    //               'Bearer ' + localStorageService.getAccessToken()
    //             return axios(originalRequest)
    //           }
    //         })
    //     }

    return Promise.reject(error);
  }
);

export default instance;
