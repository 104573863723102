import axios from "../../lib/axiosInterceptor";

export const GetRewardsApi = async (payload) => {

try{
    const url = "/Account/MyReward";
    const res = await axios.get(url,payload);
    return res.data;
}catch (err) {
    throw err;
}

}