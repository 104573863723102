import React from "react";
import { TfiReload } from "react-icons/tfi";
import error from "../../../assets/svg/servererror.svg";
import { getDeviceHeight } from "../../../utils/getDeviceHeight";

export default function ServerError() {
  let deviceHeight = getDeviceHeight;
  return (
    <section
      style={{ height: deviceHeight }}
      className=" flex flex-col justify-center items-center"
    >
      <div>
        <img src={error} alt="" />
      </div>
      <div className="text-center">
        <h1 className="font-semibold my-2 text-lg">Internal server error</h1>
        <p className="w-[70%] mx-auto text-xs">
          Sorry, there were some technical issues while processing your request.
        </p>
        <div className="my-4 flex w-full justify-center items-center  ">
          <button
            className="px-4 flex  text-xs items-center py-2 rounded bg-orange-600 text-white"
            onClick={() => window.location.reload()}
          >
            <TfiReload className="mr-2" />
            Reload Page{" "}
          </button>
        </div>
      </div>
    </section>
  );
}
